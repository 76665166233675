import Swal from "sweetalert2";
import Spinner from "../components/bootstrap/Spinner";

export const showLoader = (isShow: any) => {

    if (isShow == true) {
        let showLoader: any = Swal.fire({ title: `<div class="text-center mg-b-10"><div class="spinner-border text-primary" role="status"><span class="visually-hidden">Loading...</span></div></div>`, showConfirmButton: false, showCloseButton: false, background: "#a8a8a600", allowOutsideClick: false })
        return showLoader;
    } else {
        Swal.close()
    }
};


export const TableLoader = () => {

    return (
        <>
            <div className='col-12 d-flex justify-content-center'>
                <Spinner color='info' className='fs-3' />
            </div>
        </>

    );

};