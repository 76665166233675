import { useContext, useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Button, { ButtonGroup } from "../bootstrap/Button";
import FormGroup from "../bootstrap/forms/FormGroup";
import AlertService from "../../services/alert.service";
import Collapse from "../bootstrap/Collapse";
import Input from "../bootstrap/forms/Input";
import useDarkMode from "../../hooks/useDarkMode";
import PaginationButtons, { dataPagination } from "../PaginationButtons";
import useSelectTable from "../../hooks/useSelectTable";
import { useFormik } from "formik";
import useSortableData from "../../hooks/useSortableData";
import classNames from "classnames";
import { toasts } from "../../services/toaste.service";
import { addBoxLabel, getBoxLabel, getGenerateQrDetails, getLotDetails, getNextCartonNo } from "../../services/packingService";
import Icon from "../icon/Icon";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../bootstrap/OffCanvas";
import { TableLoader, showLoader } from "../../services/loader.services";
import NoDataMsg from "../../common/components/NoDataMsg";
import { getLicenseKey } from "../../services/application.settings";
import AuthContext from "../../contexts/authContext";
import Select from "../bootstrap/forms/Select";

function AssignBoxCount() {

    useEffect(() => {
        getBoxLabelList()
        getGenerateQrDetailsList()
    }, [])

    const assignBoxCountForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            generateQrDetailsId: '',
            boxCount: '',
            lotNo: '',
            netWeight: '',
            crossWeight: '',
            cexNo: ''
        },
        validate: (values: any) => {
            const errors: {
                generateQrDetailsId?: string;
                boxCount?: string;
                lotNo?: string;
                netWeight?: string;
                crossWeight?: string;
                cexNo?: string;
            } = {};
            if (!generateQrDetailsId) {
                errors.generateQrDetailsId = 'Required';
            }
            if (!values.netWeight) {
                errors.netWeight = 'Required';
            }
            if (!values.crossWeight) {
                errors.crossWeight = 'Required';
            }
            if (!values.cexNo) {
                errors.cexNo = 'Required';
            }
            if (!lotNo) {
                errors.lotNo = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addBoxLabelSubmit() },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { darkModeStatus } = useDarkMode();
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [addProductOffcanvas, setAddProductOffcanvas] = useState(false);

    const [boxLabelData, setBoxLabelData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')
    const [dataStatus, setDataStatus] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(boxLabelData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);


    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // Name           
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.cartonNo !== null && i.cartonNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.netWeight !== null && i.netWeight.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.crossWeight !== null && i.crossWeight.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.cexNo !== null && i.cexNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.boxCount !== null && i.boxCount.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    const [lotNo, setLotNo] = useState<any>('')
    const [countNo, setCountNo] = useState<any>('')
    const [generateQrDetailsId, setGenerateQrDetailsId] = useState<any>('')

    const selectLotNo = (e: any) => {
        let selectedData: any = generateQrDetailsData.find((data: any) => data.lotNo == e.target.value);
        setLotNo(selectedData.lotNo)
        getGenerateQrDetailsListForDropdown(selectedData.lotId)
    };

    const selectCountNo = (e: any) => {
        let selectedData: any = countNoData.find((data: any) => data.generateQrDetailsId == e.target.value);
        setCountNo(selectedData.countNo)
        setGenerateQrDetailsId(selectedData.generateQrDetailsId)
        // getNextCartonNoList(selectedData.generateQrDetailsId)
    };

    function getBoxLabelList() {
        setDataStatus(false)
        getBoxLabel(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.boxLabel;
                    if (data != undefined) {
                        setBoxLabelData(data)
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataStatus(true)
                }
            }, error => {
                toasts(error, "Error")
                setDataStatus(true)
            }
        )
    }

    function setMonitorVideoDetails() {
        return ({
            licenseKey: getLicenseKey,
            generateQrDetailsId: generateQrDetailsId,
            cartonNo: '',
            netWeight: assignBoxCountForm.values.netWeight,
            crossWeight: assignBoxCountForm.values.crossWeight,
            cexNo: assignBoxCountForm.values.cexNo,
            boxCount: assignBoxCountForm.values.boxCount,
            userAccountId: userAccountId,
        })
    }

    function addBoxLabelSubmit() {
        showLoader(true)
        if (assignBoxCountForm.isValid) {
            let monitorVideoPostData = setMonitorVideoDetails()
            addBoxLabel(monitorVideoPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getBoxLabelList()
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (assignBoxCountForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please Select Video!', type: "error" });
            setIsOpen(true);
        }
    }

    const [generateQrDetailsData, setGenerateQrDetailsData] = useState([])

    function getGenerateQrDetailsList() {
        getGenerateQrDetails(0, 1, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.generateQrDetails;
                    if (data != undefined) {
                        setGenerateQrDetailsData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [countNoData, setCountNoData] = useState([])

    function getGenerateQrDetailsListForDropdown(lotDetailsId: any) {
        getGenerateQrDetails(lotDetailsId, 1, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.generateQrDetails;
                    if (data != undefined) {
                        setCountNoData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    // const [cartonNo, setCartonNo] = useState([])

    // function getNextCartonNoList(generateQrDetailsId: any) {
    //     getNextCartonNo(generateQrDetailsId,
    //         (response) => {
    //             if (response.data.success) {
    //                 let data = response.data.data.nextCartonNo;
    //                 if (data != undefined) {
    //                     setCartonNo(data[0].getCartonNo)
    //                     console.log("getCartonNo", data[0].getCartonNo);

    //                 } else {
    //                     toasts("Undefined Data", "Error")
    //                 }
    //             }
    //             else if (response.data.success === false) {
    //                 // toasts(response.data.message, "Error")
    //             } else {
    //                 let errorCode = response.data.error[0].error_code;
    //                 let errorDescription = response.data.error[0].error_description;
    //                 toasts(errorDescription, "Error")
    //             }
    //         }, error => {
    //             toasts(error, "Error")
    //         }
    //     )
    // }

    function closeAndReset() {
        assignBoxCountForm.resetForm()
        setGenerateQrDetailsId('')
        setAddProductOffcanvas(false)
        setLotNo('')
    }

    return (
        <>
            <PageWrapper title={`Assign Box Count`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-7">
                                <CardTitle tag='div' className='h5'>Assigned Box List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Button color='primary' icon='Add' isLight onClick={() => setAddProductOffcanvas(true)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('lotNo')}
                                                className='cursor-pointer text-decoration-underline'>Lot No{' '}
                                                <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('countNo')}
                                                className='cursor-pointer text-decoration-underline'>Count No{' '}
                                                <Icon size='lg' className={getClassNamesFor('countNo')} icon='FilterList' />
                                            </th>                                           
                                            <th scope='col' onClick={() => requestSort('netWeight')}
                                                className='cursor-pointer text-decoration-underline'>Net Weight{' '}
                                                <Icon size='lg' className={getClassNamesFor('netWeight')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('crossWeight')}
                                                className='cursor-pointer text-decoration-underline'>Cross Weight{' '}
                                                <Icon size='lg' className={getClassNamesFor('crossWeight')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('cexNo')}
                                                className='cursor-pointer text-decoration-underline'>c.Ex No{' '}
                                                <Icon size='lg' className={getClassNamesFor('cexNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('boxCount')}
                                                className='cursor-pointer text-decoration-underline'>No Of Cone For Box{' '}
                                                <Icon size='lg' className={getClassNamesFor('boxCount')} icon='FilterList' />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{boxLabelData != "" ? <>{filteredData.map((item: any) => (<tr key={item.sno}><td>{item.sno}</td><td>{item.lotNo}</td><td>{item.countNo}</td><td>{item.netWeight}</td><td>{item.crossWeight}</td><td>{item.cexNo}</td><td>{item.boxCount}</td></tr>))}</>:<NoDataMsg columnsCount={10} msg={noDataMsg} />}</tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>

                    <OffCanvas setOpen={setAddProductOffcanvas} isOpen={addProductOffcanvas} titleId='addProductOffcanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={assignBoxCountForm.handleSubmit} isNotClose>
                        <OffCanvasHeader setOpen={closeAndReset}>
                            <OffCanvasTitle id='addProductOffcanvas'>Assign Box Count</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className='row g-4'>
                                <div className='col-12'>
                                    <FormGroup id='lotNo' label='Lot No'>
                                        <Select
                                            ariaLabel='Lot No'
                                            placeholder="Select Lot No"
                                            onChange={selectLotNo}
                                            value={lotNo}
                                            isValid={assignBoxCountForm.isValid}
                                            onBlur={assignBoxCountForm.handleBlur}
                                            isTouched={assignBoxCountForm.touched.lotNo}
                                            invalidFeedback={assignBoxCountForm.errors.lotNo}
                                            list={generateQrDetailsData.map((data: any) => (
                                                { value: data.lotNo, text: data.lotNo }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='generateQrDetailsId' label='Count No'>
                                        <Select
                                            ariaLabel='Count No'
                                            placeholder="Select Count No"
                                            onChange={selectCountNo}
                                            value={generateQrDetailsId}
                                            isValid={assignBoxCountForm.isValid}
                                            onBlur={assignBoxCountForm.handleBlur}
                                            isTouched={assignBoxCountForm.touched.generateQrDetailsId}
                                            invalidFeedback={assignBoxCountForm.errors.generateQrDetailsId}
                                            list={countNoData.map((data: any) => (
                                                { value: data.generateQrDetailsId, text: data.countNo }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {/* <div className='col-12'>
                                    <FormGroup id='cartonNo' label='Carton No'>
                                        <Input onChange={assignBoxCountForm.handleChange}
                                            value={cartonNo}                                          
                                            disabled
                                            placeholder="Enter Carton No" />
                                    </FormGroup>
                                </div> */}
                                <div className='col-12'>
                                    <FormGroup id='netWeight' label='Net Weight'>
                                        <Input onChange={assignBoxCountForm.handleChange} value={assignBoxCountForm.values.netWeight}
                                            isValid={assignBoxCountForm.isValid}
                                            onBlur={assignBoxCountForm.handleBlur}
                                            isTouched={assignBoxCountForm.touched.netWeight}
                                            invalidFeedback={assignBoxCountForm.errors.netWeight}
                                            placeholder="Enter Net Weight" />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='crossWeight' label='Cross Weight'>
                                        <Input onChange={assignBoxCountForm.handleChange} value={assignBoxCountForm.values.crossWeight}
                                            isValid={assignBoxCountForm.isValid}
                                            onBlur={assignBoxCountForm.handleBlur}
                                            isTouched={assignBoxCountForm.touched.crossWeight}
                                            invalidFeedback={assignBoxCountForm.errors.crossWeight}
                                            placeholder="Enter Cross Weight" />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='cexNo' label='C.Ex No'>
                                        <Input onChange={assignBoxCountForm.handleChange} value={assignBoxCountForm.values.cexNo}
                                            isValid={assignBoxCountForm.isValid}
                                            onBlur={assignBoxCountForm.handleBlur}
                                            isTouched={assignBoxCountForm.touched.cexNo}
                                            invalidFeedback={assignBoxCountForm.errors.cexNo}
                                            placeholder="Enter C.Ex No" />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='boxCount' label='No Of Cone For Box'>
                                        <Input onChange={assignBoxCountForm.handleChange} value={assignBoxCountForm.values.boxCount}
                                            isValid={assignBoxCountForm.isValid}
                                            onBlur={assignBoxCountForm.handleBlur}
                                            isTouched={assignBoxCountForm.touched.boxCount}
                                            invalidFeedback={assignBoxCountForm.errors.boxCount}
                                            placeholder="Enter Box Count" />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='info' className='w-100' type="submit" icon="Save" isDisable={!assignBoxCountForm.isValid && !!assignBoxCountForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper >
        </>
    )
}
export default AssignBoxCount;