import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../contexts/authContext";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Button, { ButtonGroup } from "../bootstrap/Button";
import FormGroup from "../bootstrap/forms/FormGroup";
import AlertService from "../../services/alert.service";
import Collapse from "../bootstrap/Collapse";
import Select from "../bootstrap/forms/Select";
import Avatar from "../Avatar";
import Input from "../bootstrap/forms/Input";
import USERS from "../../common/data/userDummyData";
import useDarkMode from "../../hooks/useDarkMode";
import classNames from "classnames";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../bootstrap/Modal";
import { deleteMonitorVideos, getMonitorVideos, updateVideoStatus, videoUpload } from "../../services/castVideos.service";
import { toasts } from "../../services/toaste.service";
import { getDepartmentList, getMonitor } from "../../services/common.service";
import Checks from "../bootstrap/forms/Checks";
import Popovers from "../bootstrap/Popovers";
import useSortableData from "../../hooks/useSortableData";
import { dataPagination } from "../PaginationButtons";
import useSelectTable from "../../hooks/useSelectTable";
import { showLoader } from "../../services/loader.services";
import { getAPIURL, getLicenseKey } from "../../services/application.settings";

function UploadVideos() {

    const videoRef = useRef<any>(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play()
                .then(() => {
                    console.error('playing');
                })
                .catch((error: any) => {
                    console.error('Error attempting to play the video:', error);
                });
        }
        document.body.style.overflow = 'hidden';
        getDepartment()
        getMonitorList()
    }, []);

    const { setPreviousVideo } = useContext(AuthContext);
    const { darkModeStatus } = useDarkMode();
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenListCard, setIsOpenListCard] = useState(true)

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenVideoModal, setIsOpenVideoModal] = useState(false)

    const [isOpenAllVideoModal, setIsOpenAllVideoModal] = useState(false)

    const [departmentId, setDepartmentId] = useState<any>(0)
    const [monitorId, setMonitorId] = useState<any>(0)
    const [monitorCount, setMonitorCount] = useState<any>(0)
    const [videos, setVideos] = useState([])
    const [allVideosList, setAllVideosList] = useState([])
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [videoPreview, setVideoPreview] = useState<any>(null);
    const [noDataMsg, setNoDataMsg] = useState('')
    const [videoUrl, setVideoUrl] = useState('')

    const [departmentVideoData, setDepartmentVideoData] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10000);
    const { items } = useSortableData(departmentVideoData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const { selectTable, SelectAllCheck, selectItemHandleChange } = useSelectTable(onCurrentPageData);

    function selectedDepartment(e: any) {
        let departmentId = e.target.value
        setDepartmentId(departmentId)
    }

    function selectedMonitor(e: any) {
        let monitorId = e.target.value
        setMonitorId(monitorId)
    }

    const handleFileChange = (event: any) => {
        const newFileName = `${departmentId}_${monitorId}-${monitorCount + 1}.mp4`;
        const file = event.target.files[0];
        const renamedFile = new File([file], newFileName);
        setSelectedFile(renamedFile);

        if (file) {
            const videoUrl = URL.createObjectURL(file);
            setVideoPreview(videoUrl);
        }
    };

    function showVideo(videoName: any) {
        setIsOpenVideoModal(true)
        setVideoUrl(`${getAPIURL()}videos/${videoName}`)        
    }

    function selectedDepartmentVideoList(data: any) {
        setIsOpenAllVideoModal(true)
        let videoList = allVideosList.filter((item: any) => item.departmentId == data.departmentId && item.videoName != '0_0.mp4')
        setDepartmentVideoData(videoList);
    }

    const [departmentData, setDepartmentData] = useState<any>([])
    const [monitorData, setMonitorData] = useState<any>([])

    function onUpload() {
        showLoader(true)
        if (!selectedFile) {
            showLoader(false)
            toasts("Please select a file first.", "Error")
            return;
        }
        const fileData = new FormData();
        fileData.append('licenseKey', "5566")
        fileData.append('monitorId', monitorId)
        fileData.append('departmentId', departmentId)
        fileData.append('document', selectedFile)
        fileData.append('userAccountId', '230')
        fileData.append('videoCount', monitorCount + 1)

        videoUpload(fileData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    setIsOpenModal(false)
                    getMonitorVideosList()
                    closeAndReset()
                    showLoader(false)
                }
                else if (data.success == false) {
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                    setIsOpenModal(false)
                    showLoader(false)
                }
                else {
                    setIsOpenModal(false)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                    showLoader(false)
                }
            }
            , (error) => {
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
                setIsOpenModal(false)
                showLoader(false)
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.departmentList;
                    if (data != undefined) {
                        setDepartmentData(data)
                        getMonitorVideosList()
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setDepartmentData({ departmentId: "", departmentNameName: response.data.message });
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMonitorList() {
        getMonitor(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.monitor;
                    if (data != undefined) {
                        setMonitorData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setDepartmentData({ monitorId: "", monitorName: response.data.message });
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMonitorVideosList() {
        getMonitorVideos(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.monitorVideos;
                    if (data != undefined) {

                        let videoData: any = []

                        if (data != undefined) {
                            data.forEach((item: any) => {
                                const obj = {
                                    videoName: item.videoName,
                                    departmentId: item.departmentId,
                                    departmentName: item.departmentName,
                                    monitorId: item.monitorId,
                                    monitorName: item.monitorName,
                                    videoUrl: item.videoUrl
                                };
                                videoData.push(obj);
                            });
                        }

                        const groupedData = videoData.reduce((acc: any, item: any) => {
                            if (!acc[item.monitorId]) {
                                acc[item.monitorId] = [];
                            }
                            acc[item.monitorId].push(item);
                            return acc;
                        }, {});

                        const lastItems: any = Object.values(groupedData).map((items: any) => items[items.length - 1]);
                        let getAllData = lastItems.filter((item: any) => item.videoName != '0_0.mp4')

                        for (let i = 0; i < getAllData.length; i++) {
                            getAllData[i].sno = i + 1;
                        }

                        setVideos(getAllData);

                        for (let i = 0; i < videoData.length; i++) {
                            let id = i + 1
                            videoData[i].id = id.toString();
                        }

                        setAllVideosList(videoData);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setMonitorVideoDetails() {

        return ({
            licenseKey: getLicenseKey,
            videoName: selectTable.values.selectedList
        })
    }

    function deleteMonitorVideosSubmit() {
        showLoader(true)
        if (selectTable.values.selectedList.length > 0) {
            let monitorVideoPostData = setMonitorVideoDetails()
            deleteMonitorVideos(monitorVideoPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getMonitorVideosList()
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (selectTable.values.selectedList.length > 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please Select Video!', type: "error" });
            setIsOpen(true);
        }
    }

    function setUpdateVideoStatus() {
        return ({
            licenseKey: getLicenseKey,
            departmentId: departmentId,
            monitorId: monitorId,
        })
    }

    function closeAndReset() {
        setDepartmentId('')
        setMonitorId('')
        setSelectedFile([])
        setVideoPreview(null)
        setIsOpenModal(false)
        setVideoPreview(null)
        setIsOpenAllVideoModal(false)
        selectTable.values.selectedList = [];
    }

    return (
        <>
            <PageWrapper title={`Upload Video`}>
                <Page container='fluid'>

                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list' className='d-print-none'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='Upload' iconColor='info'>
                                    <CardTitle>
                                        Cast Videos
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row g-4'>
                                    <div className='col-md-3'>
                                        <FormGroup id='departmentId' label='Department' isFloating>
                                            <Select
                                                ariaLabel='Department'
                                                onChange={(e: any) => selectedDepartment(e)}
                                                value={departmentId}
                                                list={departmentData.map((data: any) => (
                                                    { value: data.departmentId, text: data.departmentName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    {departmentId > 0 ?
                                        <div className='col-md-3'>
                                            <FormGroup id='monitorId' label='Monitor' isFloating>
                                                <Select
                                                    ariaLabel='Monitor'
                                                    onChange={(e: any) => selectedMonitor(e)}
                                                    value={monitorId}
                                                    list={monitorData.map((data: any) => (
                                                        { value: data.monitorId, text: data.monitorName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null
                                    }
                                    {monitorId > 0 ?
                                        <div className='col-md-3'>
                                            <Button className="mt-2" icon="Check" color='primary' onClick={() => setIsOpenModal(true)}>
                                                Submit
                                            </Button>
                                        </div> : null
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    {videos.length > 0 ?
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>Video List</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable>

                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th className="d-print-none" style={{ width: '13%' }}>S.No</th>
                                            <th className="d-print-none" style={{ width: '33%' }}>Department</th>
                                            <th className="d-print-none" style={{ width: '33%' }}>Monitor</th>
                                            <th className="d-print-none">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Action</th>
                                        </tr>
                                    </thead>
                                    {videos.map((item: any) => (
                                        <tbody key={item.sno}>
                                            <tr>
                                                <th>{item.sno}</th>
                                                <th>{item.departmentName}</th>
                                                <th>{item.monitorName}</th>
                                                <th>
                                                    <Button isOutline={!darkModeStatus} color='primary' isLight={darkModeStatus}
                                                        className={classNames('text-nowrap', { 'border-light': !darkModeStatus, })} icon='RemoveRedEye' onClick={() => showVideo(item.videoName)}>View
                                                    </Button>&nbsp;&nbsp;&nbsp;
                                                    <Button isOutline={!darkModeStatus} color='danger' isLight={darkModeStatus} icon='Delete'
                                                        className={classNames('text-nowrap', { 'border-light': !darkModeStatus, })} onClick={() => selectedDepartmentVideoList(item)}>Delete
                                                    </Button>
                                                </th>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            </CardBody>
                        </Card> : null
                    }

                    <Modal
                        isCentered
                        setIsOpen={setIsOpenModal}
                        isOpen={isOpenModal}
                        titleId='upcomingEdit'
                        size='lg'>
                        <ModalHeader setIsOpen={() => { setIsOpenModal(false); setSelectedFile(null); setVideoPreview(null) }}>
                            <ModalTitle id='examplemailcontent'>
                                <div className="h4">Upload</div>
                            </ModalTitle>
                        </ModalHeader>
                        <ModalBody>
                            <div className='col-12'>
                                <div className='row g-4 align-items-center'>
                                    <div className='col-lg-auto'>
                                        {videoPreview ?
                                            <video ref={videoRef} src={videoPreview} controls muted loop autoPlay width="300" >
                                                <source src={videoPreview} type="video/mp4" />
                                            </video> :
                                            <Avatar
                                                srcSet={require('../../assets/img/videoIcon.png')}
                                                src={require('../../assets/img/videoIcon.png')}
                                                color={USERS.JOHN.color}
                                                rounded={3}
                                                style={{ width: '200px', height: '180px' }}
                                            />
                                        }
                                    </div>
                                    <div className='col-lg'>
                                        <div className='row g-4'>
                                            <div className='col-auto'>
                                                <Input
                                                    type='file'
                                                    autoComplete='photo'
                                                    ariaLabel='Upload image file'
                                                    onChange={handleFileChange}
                                                />
                                                <br />
                                                <Button color='dark' isLight icon='Upload' onClick={onUpload}>
                                                    Upload
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal
                        isCentered
                        setIsOpen={setIsOpenVideoModal}
                        isOpen={isOpenVideoModal}
                        titleId='monitorVideo'
                        size='lg'>
                        <ModalHeader setIsOpen={setIsOpenVideoModal}>
                            <></>
                        </ModalHeader>
                        <ModalBody>
                            <div className='col-12'>
                                <video ref={videoRef} width="100%" controls muted loop autoPlay>
                                    <source src={videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal
                        isCentered
                        setIsOpen={setIsOpenAllVideoModal}
                        isOpen={isOpenAllVideoModal}
                        titleId='allMonitorVideo'
                        isStaticBackdrop
                        size='lg'>
                        <ModalHeader setIsOpen={setIsOpenAllVideoModal}>
                            <ModalTitle id='examplemailcontent'>
                                <div className="h4">Video List</div>
                            </ModalTitle>
                        </ModalHeader>
                        <ModalBody>
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr>
                                        <th scope='col'>Select</th>
                                        <th className="d-print-none" style={{ width: '30%' }}>Department</th>
                                        <th className="d-print-none" style={{ width: '30%' }}>Monitor</th>
                                        <th className="d-print-none">Action</th>
                                    </tr>
                                </thead>
                                {onCurrentPageData.map((item: any) => (
                                    <tbody key={item.videoName}>
                                        <tr>
                                            <td><Checks
                                                className="border border-primary"
                                                // disabled={item.departmentLength == 1 ? true : false}
                                                id={item.videoName.toString()}
                                                name='selectedList'
                                                value={item.videoName}
                                                onChange={selectTable.handleChange}
                                                checked={selectTable.values.selectedList.includes(
                                                    // @ts-ignore
                                                    item.departmentLength == 1 ? '' :
                                                        item.videoName.toString(),
                                                )}
                                            /></td>
                                            <th>{item.departmentName}</th>
                                            <th>{item.monitorName}</th>
                                            <th>
                                                <Popovers
                                                    desc={
                                                        <video ref={videoRef} width="100%" controls muted loop autoPlay>
                                                            <source src={`${getAPIURL()}videos/${item.videoName}`} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    }
                                                    placement='right-end'>
                                                    <Button icon='RemoveRedEye' isOutline={!darkModeStatus} color='dark' isLight={darkModeStatus}
                                                        className={classNames('text-nowrap', { 'border-light': !darkModeStatus, })}>View
                                                    </Button>
                                                </Popovers>
                                            </th>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color='info'
                                isOutline
                                className='border-0'
                                onClick={() => setIsOpenAllVideoModal(false)}
                            >
                                Close
                            </Button>
                            <Button color='danger' icon="Delete" isDisable={selectTable.values.selectedList.length == 0 ? true : false} onClick={deleteMonitorVideosSubmit}>
                                Delete
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Page>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper >
        </>
    )
}
export default UploadVideos;