import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Collapse from '../bootstrap/Collapse'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import FormGroup from '../bootstrap/forms/FormGroup'
import Input from '../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Select from '../bootstrap/forms/Select'
import Icon from '../icon/Icon'
import { addGenerateQrDetails, addLotDetails, getGenerateQrDetails, getLotDetails } from '../../services/packingService'
import { toasts } from '../../services/toaste.service'
import { getLicenseKey } from '../../services/application.settings'
import AuthContext from '../../contexts/authContext'
import { showLoader } from '../../services/loader.services'
import AlertService from '../../services/alert.service'
import NoDataMsg from '../../common/components/NoDataMsg'
import InputGroup from '../bootstrap/forms/InputGroup'
import useSortableData from '../../hooks/useSortableData'
import { dataPagination } from '../PaginationButtons'
import { addSubModuleType, getSubModuleType } from '../../services/common.service'

const GenerateQR = () => {

    useEffect(() => {
        // showLoader(false)
        getLotDetailsList()
        getGenerateQrDetailsList()
        getCountDetailsList()
    }, [])

    const generateQrForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            lotId: '',
            countId: '',
            countNo: '',
            totalQr: '',
            lotNo: '',
        },
        validate: (values: any) => {
            const errors: {
                lotId?: string;
                totalQr?: string;
                countId?: string;
                countNo?: string;
                lotNo?: string;
            } = {};
            if (!lotId && isVisibleOnMillLotNo == true) {
                errors.lotId = 'Required';
            }
            if (!countId && isVisibleOnMillLotNo == true) {
                errors.countId = 'Required';
            }
            if (!values.totalQr) {
                errors.totalQr = 'Required';
            }
            if (!lotNo && isVisibleOnMillLotNo == false) {
                errors.lotNo = 'Required';
            }
            if (!countNo && isVisibleOnCountNo == false) {
                errors.countNo = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addGenerateQrDetailsSubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [generateQrDetailsData, setGenerateQrDetailsData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')

    const [isVisibleOnMillLotNo, setIsVisibleOnMillLotNo] = useState(true)
    const [isVisibleOnCountNo, setIsVisibleOnCountNo] = useState(true)

    const [lotNo, setLotNo] = useState('')
    const [countNo, setCountNo] = useState('')
    const [countId, setCountId] = useState('')
    const [lotId, setLotId] = useState('')

    const [lotDetailsData, setLotDetailsData] = useState<any>([])
    const [countDetailsData, setCountDetailsData] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(generateQrDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.countNo !== null && i.countNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.totalQr !== null && i.totalQr.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.dateStamp !== null && i.dateStamp.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    const selectLotNo = (e: any) => {
        let selectedData: any = lotDetailsData.find((data: any) => data.subModuleTypeId == e.target.value);
        setLotNo(isVisibleOnMillLotNo ? selectedData.subModuleType : e.target.value)
        setLotId(isVisibleOnMillLotNo ? selectedData.subModuleTypeId : e.target.value)
    };

    const selectCountNo = (e: any) => {
        let selectedData: any = countDetailsData.find((data: any) => data.subModuleTypeId == e.target.value);
        setCountNo(isVisibleOnCountNo ? selectedData.subModuleType : e.target.value)
        setCountId(isVisibleOnCountNo ? selectedData.subModuleTypeId : e.target.value)
    };

    const toggleVisibilityOnMillLotNo = () => {
        setIsVisibleOnMillLotNo(!isVisibleOnMillLotNo);
        setLotNo('')
        setLotId('')
    };

    const toggleVisibilityOnCountNo = () => {
        setIsVisibleOnCountNo(!isVisibleOnCountNo);
        setCountNo('')
        setCountId('')
    };

    function getLotDetailsList() {
        getSubModuleType(1,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subModuleType;
                    if (data != undefined) {
                        setLotDetailsData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setIsVisibleOnMillLotNo(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCountDetailsList() {
        getSubModuleType(2,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subModuleType;
                    if (data != undefined) {
                        setCountDetailsData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setIsVisibleOnCountNo(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setGenerateQrDetails() {
        return ({
            licenseKey: getLicenseKey,
            countNo: countNo,
            totalQr: generateQrForm.values.totalQr,
            userAccountId: userAccountId,
            lotNo: lotNo,
        })
    }

    function addGenerateQrDetailsSubmit() {
        showLoader(true)
        let generateQrDetailsPostData = setGenerateQrDetails()
        if (generateQrForm.isValid) {
            addGenerateQrDetails(generateQrDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);

                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (generateQrForm.isValid == false) {
            toasts("Please fill all details", "Error");
        }
    }

    function getGenerateQrDetailsList() {
        getGenerateQrDetails(0, 0, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.generateQrDetails;
                    if (data != undefined) {
                        setGenerateQrDetailsData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedData = i
            window.open(selectedData.pdfPath);
        }
    }

    function closeAndReset() {
        generateQrForm.resetForm()
        getGenerateQrDetailsList()
        setLotNo('')
        setLotId('')
        setCountId('')
        setIsVisibleOnMillLotNo(true)
        setIsVisibleOnCountNo(true)
        getLotDetailsList()
        getCountDetailsList()
    }

    return (
        <PageWrapper title={`Generate QR`}>
            <Page>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' className='d-print-none' tag="form" noValidate onSubmit={generateQrForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='QrCode' iconColor='info'>
                                <CardTitle>
                                    Generate QR For Cone
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <InputGroup>
                                        {isVisibleOnMillLotNo ?
                                            <FormGroup id='lotId' label='Lot No' isFloating>
                                                <Select
                                                    ariaLabel='Lot No'
                                                    onChange={selectLotNo}
                                                    value={lotId}
                                                    isValid={generateQrForm.isValid}
                                                    onBlur={generateQrForm.handleBlur}
                                                    isTouched={generateQrForm.touched.lotId}
                                                    invalidFeedback={generateQrForm.errors.lotId}
                                                    list={lotDetailsData.map((data: any) => (
                                                        { value: data.subModuleTypeId, text: data.subModuleType }
                                                    ))}
                                                />
                                            </FormGroup> :
                                            <FormGroup id='lotNo' label='Lot No' isFloating>
                                                <Input onInput={selectLotNo}
                                                    value={lotNo}
                                                    isValid={generateQrForm.isValid}
                                                    onBlur={generateQrForm.handleBlur}
                                                    isTouched={generateQrForm.touched.lotNo}
                                                    invalidFeedback={generateQrForm.errors.lotNo}
                                                    placeholder="Enter Lot No" />
                                            </FormGroup>
                                        }
                                        <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={toggleVisibilityOnMillLotNo}></Button>
                                    </InputGroup>
                                </div>

                                <div className='col-3'>
                                    <InputGroup>
                                        {isVisibleOnCountNo ?
                                            <FormGroup id='countId' label='Count No' isFloating>
                                                <Select
                                                    ariaLabel='Count No'
                                                    onChange={selectCountNo}
                                                    value={countId}
                                                    isValid={generateQrForm.isValid}
                                                    onBlur={generateQrForm.handleBlur}
                                                    isTouched={generateQrForm.touched.countId}
                                                    invalidFeedback={generateQrForm.errors.countId}
                                                    list={countDetailsData.map((data: any) => (
                                                        { value: data.subModuleTypeId, text: data.subModuleType }
                                                    ))}
                                                />
                                            </FormGroup> :
                                            <FormGroup id='countNo' label='Count No' isFloating>
                                                <Input onInput={selectCountNo}
                                                    value={countNo}
                                                    isValid={generateQrForm.isValid}
                                                    onBlur={generateQrForm.handleBlur}
                                                    isTouched={generateQrForm.touched.countNo}
                                                    invalidFeedback={generateQrForm.errors.countNo}
                                                    placeholder="Enter Count No" />
                                            </FormGroup>
                                        }
                                        <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={toggleVisibilityOnCountNo}></Button>
                                    </InputGroup>
                                </div>                                
                                <div className='col-2'>
                                    <FormGroup id='totalQr' label='Total QR' isFloating>
                                        <Input onChange={generateQrForm.handleChange} value={generateQrForm.values.totalQr}
                                            isValid={generateQrForm.isValid}
                                            onBlur={generateQrForm.handleBlur}
                                            isTouched={generateQrForm.touched.totalQr}
                                            invalidFeedback={generateQrForm.errors.totalQr}
                                            type='number'
                                            placeholder="Enter Total QR" />
                                    </FormGroup>
                                </div>                                
                                <div className='col-md-2'>
                                    <Button className="mt-2" icon="WifiProtectedSetup" color='primary' onClick={generateQrForm.handleSubmit}>
                                        Generate
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                {generateQrDetailsData != '' ?
                    <Card stretch data-tour='list' className='d-print-none'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle>
                                    Generate QR List
                                </CardTitle>
                            </CardLabel>
                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                            </ButtonGroup>
                        </CardHeader>
                        <CardBody>
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr>
                                        <th className='cursor-pointer text-decoration-underline'>S.No</th>
                                        <th className='cursor-pointer text-decoration-underline'>Lot No</th>
                                        <th className='cursor-pointer text-decoration-underline'>Count No</th>
                                        <th className='cursor-pointer text-decoration-underline'>Total Qr</th>                                       
                                        <th className='cursor-pointer text-decoration-underline'>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {filteredData.map((item: any) => (
                                    <tbody key={item.generateQrDetailsId}>
                                        <tr>
                                            <td>{item.sno}</td>
                                            <td>{item.lotNo}</td>
                                            <td>{item.countNo}</td>
                                            <td>{item.totalQr}</td>
                                            <td>{item.dateStamp}</td>
                                            <td className="d-print-none"><Button color='success' isLight icon='Download' aria-label='Download' onClick={() => onPrint(item)}></Button></td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </CardBody>
                    </Card> : null
                }

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    )
}

export default GenerateQR