import { useContext, useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Button, { ButtonGroup } from "../bootstrap/Button";
import FormGroup from "../bootstrap/forms/FormGroup";
import AlertService from "../../services/alert.service";
import Collapse from "../bootstrap/Collapse";
import Input from "../bootstrap/forms/Input";
import useDarkMode from "../../hooks/useDarkMode";
import PaginationButtons, { dataPagination } from "../PaginationButtons";
import useSelectTable from "../../hooks/useSelectTable";
import { useFormik } from "formik";
import useSortableData from "../../hooks/useSortableData";
import classNames from "classnames";
import { toasts } from "../../services/toaste.service";
import { addBoxLabel, getBoxLabel, getBoxLabelQrDetails, getScannedBarcodeDetails, getScannedQrDetails, updateScannedQrStatus } from "../../services/packingService";
import Icon from "../icon/Icon";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../bootstrap/OffCanvas";
import { TableLoader, showLoader } from "../../services/loader.services";
import NoDataMsg from "../../common/components/NoDataMsg";
import { getAPIURL, getLicenseKey } from "../../services/application.settings";
import AuthContext from "../../contexts/authContext";

function BundleStock() {

    useEffect(() => {
        getScannedBarcodeDetailsList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { darkModeStatus } = useDarkMode();
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [scannedBarcodeDetails, setScannedBarcodeDetails] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')
    const [dataStatus, setDataStatus] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(scannedBarcodeDetails);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);


    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // Name           
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.countNo !== null && i.countNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.totalScannedCount !== null && i.totalScannedCount.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    function getScannedBarcodeDetailsList() {
        setDataStatus(false)
        getScannedBarcodeDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.scannedBarcodeDetails;
                    if (data != undefined) {
                        setScannedBarcodeDetails(data)
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataStatus(true)
                }
            }, error => {
                toasts(error, "Error")
                setDataStatus(true)
            }
        )
    }

    return (
        <>
            <PageWrapper title={`Bundle Stock`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-7">
                                <CardTitle tag='div' className='h5'>Bundle Stock</CardTitle>
                            </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                            </ButtonGroup>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th scope='col' style={{ width: '10%' }} onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            <th scope='col' style={{ width: '13%' }} onClick={() => requestSort('lotNo')}
                                                className='cursor-pointer text-decoration-underline'>Lot No{' '}
                                                <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' style={{ width: '13%' }} onClick={() => requestSort('countNo')}
                                                className='cursor-pointer text-decoration-underline'>Count No{' '}
                                                <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' style={{ width: '13%' }} onClick={() => requestSort('boxCount')}
                                                className='cursor-pointer text-decoration-underline'>Total Box{' '}
                                                <Icon size='lg' className={getClassNamesFor('boxCount')} icon='FilterList' />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{scannedBarcodeDetails != "" ?<>{filteredData.map((item: any) => (<tr key={item.sno}><td>{item.sno}</td><td>{item.lotNo}</td><td>{item.countNo}</td><td>{item.totalScannedCount}</td></tr>))}</>:<NoDataMsg columnsCount={10} msg={noDataMsg} />}</tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>

                    <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
                </Page>
            </PageWrapper >
        </>
    )
}
export default BundleStock;