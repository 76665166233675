import React from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { TableLoader } from '../../../services/loader.services';
import Spinner from '../../../components/bootstrap/Spinner';

const LoaderComponent = () => {
    return (
        <PageWrapper>
            <Page>
                <div style={{ marginTop: "25%" }}>
                    <div className='col-12 d-flex justify-content-center'>
                        <Spinner color='info' size='3rem' className='fs-3' />
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default LoaderComponent;
