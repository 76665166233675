import axios, {AxiosResponse} from 'axios';

const service = () => {
  const get = async (
    url: string,
    success: (arg0: AxiosResponse<any, any>) => any,
    failure: (arg0: any) => void,
  ) => {
    return await axios
      .get(url)
      .then(res => success(res))
      .catch(error => {
        failure(error);
      });
  };

  const post = async (
    url: string,
    data: any,
    success: (arg0: AxiosResponse<any, any>) => any,
    failure: (arg0: any) => void,
  ) => {
    axios
      .post(url, data)
      .then(res => success(res))
      .catch(error => {
        failure(error);
      });
  };

  return {
    get,
    post
  }
};

export default service