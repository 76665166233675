import React, { useContext, useEffect, useState } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import Search from '../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';

const DashboardHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const { userTypeId, userAccountId } = useContext(AuthContext);
	const [userData, setUserData] = useState<any>({});

	useEffect(() => {
	}, []);

	return (
		// <Header>
		// 	<HeaderLeft>
		// 		{userTypeId != 10 ? <Search /> :
		// 			<div className='d-flex align-items-center'>
		// 				<div className='row g-4'>
		// 					<div className='col-md-auto'>
		// 						<div
		// 							className={classNames('fs-3', 'fw-bold', {
		// 								'text-dark': !darkModeStatus,
		// 							})}>
		// 							Hi, {userData.studentName}!
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		}

		// 	</HeaderLeft>
		// 	<CommonHeaderRight afterChildren={<CommonHeaderChat />} />

		// </Header>
		<></>
	);
};

export default DashboardHeader;
