import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import { useFormik } from 'formik';
import { loginForStaff, validateUserFromEmail } from '../../../services/login.service';
import { getLicenseKey } from '../../../services/application.settings';
import { toasts } from '../../../services/toaste.service';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
				<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {

	const { setUserAccountId, setUserTypeId } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [userName, setUserName] = useState<any>('');
	const [studentLoginStatus, setStudentLoginStatus] = useState<boolean>(false);	

	const navigate = useNavigate();
	// const handleOnClick = useCallback(() => navigate('../generateQR'), [navigate]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const staffLoginForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			emailOrMobile: '',
			password: '',
		},
		validate: (values) => {
			const errors: { emailOrMobile?: string; password?: string } = {};

			if (!values.emailOrMobile) {
				errors.emailOrMobile = 'Required';
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailOrMobile)
			) {
				errors.emailOrMobile = 'Invalid email address';
			}

			if (!values.password) {
				errors.password = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => { staffLogin() },
	});

	function setLogin() {
		return {
			emailOrMobile: staffLoginForm.values.emailOrMobile,
			password: staffLoginForm.values.password
		}
	}

	function setValidateStaffDetails() {

		return ({
			email: staffLoginForm.values.emailOrMobile,
			licenseKey: getLicenseKey,
		})
	}


	const handleContinue = (emailOrMobile: any) => {
		setIsLoading(true);
		setTimeout(() => {
			if (emailOrMobile != undefined) {
				let validateUserPostData = setValidateStaffDetails();

				// if (validateUserPostData.email == 'admin@gmail.com') {
				// 	setUserName('Admin')
				// 	setIsLoading(false);
				// 	setSignInPassword(true);
				// }
				// else if (validateUserPostData.email != 'admin@gmail.com') {
				// 	staffLoginForm.setFieldError('emailOrMobile', 'Invalid email address');
				// 	setIsLoading(false);
				// }
				validateUserFromEmail(validateUserPostData,
					(response) => {
						const data = response.data;
						if (data.success == true) {
							setUserName(response.data.data[0].userName)
							setIsLoading(false);
							setSignInPassword(true);
						}
						else if (data.success == false) {
							staffLoginForm.setFieldError('emailOrMobile', data.message);
							setIsLoading(false);
						}
						else {
							let errorCode = response.data.error[0].error_code;
							let errorDescription = response.data.error[0].error_description;
							toasts(errorDescription, "Error")
						}
					}
					, (error) => {
						toasts(error, "Error")
					}
				)
			} else if (emailOrMobile == undefined) {
				staffLoginForm.setFieldError('emailOrMobile', 'Please fill all the details!');
				setIsLoading(false);
			}
		}, 1000);
	};

	function staffLogin() {
		setIsLoading(true);
		setTimeout(() => {
			if (staffLoginForm.isValid) {
				let loginData = setLogin();
				// if (loginData.password == 'welcome') {
				// 	setUserName('Admin')
				// 	setIsLoading(false);
				// 	handleOnClick();
				// 	localStorage.setItem('userAccountId', "1");
				// 	localStorage.setItem('userTypeId', "1");
				// 	if (setUserAccountId) {
				// 		setUserAccountId(1);
				// 	}
				// 	if (setUserTypeId) {
				// 		setUserTypeId(1);
				// 	}
				// 	toasts("Welcome Admin, you have successfully logged", "Success");
				// }
				// else if (loginData.password != 'welcome') {
				// 	staffLoginForm.setFieldError('password', 'Password was wrong');
				// 	setIsLoading(false);
				// }
				loginForStaff(loginData,
					(response) => {
						const data = response.data;
						if (data.success == true) {
							toasts(data.message, "Success");
							let loginData = response.data.data.loginWithPassword;
							localStorage.setItem('userAccountId', loginData.userAccountId);
							localStorage.setItem('userTypeId', loginData.userTypeId);

							if (setUserAccountId) {
								setUserAccountId(loginData.userAccountId);
							}
							if (setUserTypeId) {
								setUserTypeId(loginData.userTypeId);
							}
							setIsLoading(false);
							let screenPath = loginData.screenPath
							navigate(`../${screenPath}`)
						}
						else if (data.success == false) {
							staffLoginForm.setFieldError('password', data.message);
							setIsLoading(false);
						}
						else {
							let errorCode = response.data.error[0].error_code;
							let errorDescription = response.data.error[0].error_description;
							staffLoginForm.setFieldError('password', errorDescription);
							setIsLoading(false);
						}
					}
					, (error) => {
						toasts(error, "Error")
					}
				)
			} else if (staffLoginForm.isValid == false) {

				staffLoginForm.setFieldError('password', 'Please fill all the details!');
				setIsLoading(false);
			}
		}, 1000);
	}

	function resetForm() {
		staffLoginForm.resetForm();
	}

	const formatDateForDB = (dateString: any): any => {
		const dateDB = dateString.split('/').reverse().join('/');
		return dateDB;
	};

	return (

		<PageWrapper
			isProtected={false}
			title={studentLoginStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !studentLoginStatus, 'bg-light': studentLoginStatus })}>
			<Page className='p-0' container='fluid'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/login'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo width={180} height={180} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col-12'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={studentLoginStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													resetForm()
												}}>
												Login
											</Button>
										</div>
										{/* <div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!studentLoginStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setStudentLoginStatus(!studentLoginStatus);
													resetForm()
												}}>
												Student
											</Button>
										</div> */}
									</div>
								</div>

								<LoginHeader isNewUser={studentLoginStatus} />



								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='emailOrMobile'
											isFloating
											label='Your email'
											className={classNames({
												'd-none': signInPassword,
											})}>
											<Input
												autoComplete='username'
												value={staffLoginForm.values.emailOrMobile}
												isTouched={staffLoginForm.touched.emailOrMobile}
												invalidFeedback={staffLoginForm.errors.emailOrMobile}
												isValid={staffLoginForm.isValid}
												onChange={staffLoginForm.handleChange}
												onBlur={staffLoginForm.handleBlur}
												onFocus={() => {staffLoginForm.setErrors({});}}
												onKeyDownCapture={(e: any) => {
													if (e.key === "Enter") {
														handleContinue(staffLoginForm.values.emailOrMobile)
													}
												}}
											/>
										</FormGroup>
										{signInPassword && (
											<div className='text-center h4 mb-3 fw-bold'>
												Hi, {userName}.
											</div>
										)}
										<FormGroup
											id='password'
											isFloating
											label='Password'
											className={classNames({
												'd-none': !signInPassword,
											})}>
											<Input
												type='password'
												autoComplete='current-password'
												value={staffLoginForm.values.password}
												isTouched={staffLoginForm.touched.password}
												invalidFeedback={staffLoginForm.errors.password}
												isValid={staffLoginForm.isValid}
												onChange={staffLoginForm.handleChange}
												onBlur={staffLoginForm.handleBlur}
												onKeyDownCapture={(e: any) => {
													if (e.key === "Enter") {
														staffLoginForm.handleSubmit()
													}
												}}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										{!signInPassword ? (
											<Button
												color='warning'
												className='w-100 py-3'
												isDisable={!staffLoginForm.values.emailOrMobile}
												onClick={() => handleContinue(staffLoginForm.values.emailOrMobile)}
											>
												{isLoading && (
													<Spinner isSmall inButton isGrow />
												)}
												Continue
											</Button>
										) : (
											<Button
												color='warning'
												className='w-100 py-3'
												onClick={staffLoginForm.handleSubmit}
											>{isLoading && (
												<Spinner isSmall inButton isGrow />
											)}
												Login
											</Button>
										)}
									</div>
								</form>

								{/* BEGIN :: Social Login */}
								{/* {!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												OR
											</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div>
										</>
									)} */}
								{/* END :: Social Login */}
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': studentLoginStatus,
									'link-dark': !studentLoginStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': studentLoginStatus,
									'link-dark': !studentLoginStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper >
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
