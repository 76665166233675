import dayjs from "dayjs";
import service from "../api/api.services";
import { AxiosResponse } from "axios";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const videoUpload = (monitorPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `videoUpload`, monitorPostData,
        response => {
            success(response)
        },
        error => {
            failure("videoUpload - " + error.message)
            console.log('error caught in service : videoUpload')
        }
    );

export const getMonitorVideos = (monitorId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getMonitorVideos/${getLicenseKey}/${monitorId}`,
        response => {
            success(response)
        },
        error => {
            failure("getMonitorVideos - " + error.message)
            console.log('error caught in service : getMonitorVideos')
        },
    );

export const deleteMonitorVideos = (monitorVideoPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteMonitorVideos`, monitorVideoPostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteMonitorVideos - " + error.message)
            console.log('error caught in service : deleteMonitorVideos')
        }
    );

export const updateVideoStatus = (videoStatusPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateVideoStatus`, videoStatusPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateVideoStatus - " + error.message)
            console.log('error caught in service : updateVideoStatus')
        }
    );