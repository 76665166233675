import React, { FC, useState } from 'react';
import Icon from '../../components/icon/Icon';
import Spinner from '../../components/bootstrap/Spinner';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';

interface IOpenCardProps {
    isOpenListCard: boolean;
    setIsOpenListCard(...args: unknown[]): unknown;
    isLoader?: boolean;
}

const OpenCardComponent: FC<IOpenCardProps> = ({ isOpenListCard, setIsOpenListCard, isLoader }) => {

    const { darkModeStatus } = useDarkMode();

    return (
        <>
            <div className='col-12 d-flex justify-content-center'>
                {isLoader ?
                    <Spinner color='info' className='fs-3' />
                    :
                    <>
                        {isOpenListCard ?
                            <Icon icon='ExpandLess'
                                className={classNames(
                                    'mb-0 h2',
                                    {
                                        'text-dark': !darkModeStatus,
                                        'text-white': darkModeStatus,
                                    },
                                )}
                                onClick={() => setIsOpenListCard(!isOpenListCard)} />
                            :
                            <Icon icon='ExpandMore' className={classNames(
                                'mb-0 h2',
                                {
                                    'text-dark': !darkModeStatus,
                                    'text-white': darkModeStatus,
                                },
                            )}
                                onClick={() => setIsOpenListCard(!isOpenListCard)} />
                        }
                    </>
                }
            </div>
        </>
    );
}

export default OpenCardComponent;