import React, { useContext, useEffect, useRef, useState } from 'react';
import Page from '../../layout/Page/Page';
import Button, { ButtonGroup } from '../bootstrap/Button';
import AuthContext from '../../contexts/authContext';
import { getMonitorVideos } from '../../services/castVideos.service';
import { toasts } from '../../services/toaste.service';
import { getDepartmentList, getMonitorVideoList, getMonitor } from '../../services/common.service';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { getAPIURL } from '../../services/application.settings';

const OpenVideo = ({ monitorId }: any) => {

  const videoRef = useRef<any>(null);
  const { previousVideo } = useContext(AuthContext);
  const [isPlaying, setIsPlaying] = useState(true);
  const [videos, setVideos] = useState([])
  const [videoUrl, setVideoUrl] = useState<any>(null)
  const [noDataMsg, setNoDataMsg] = useState('')

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error: any) => {
          console.error('Error attempting to play the video:', error);
        });
    }
    document.body.style.overflow = 'hidden';
    getDepartment()
    getMonitorList()
    getMonitorVideo()
  }, []);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSkip = (time: any) => {
    videoRef.current.currentTime += time;
  };

  const [departmentData, setDepartmentData] = useState<any>([])
  const [monitorData, setMonitorData] = useState<any>([])

  function getDepartment() {
    getDepartmentList(
      (response) => {
        if (response.data.success) {
          let data = response.data.data.departmentList;
          if (data != undefined) {
            setDepartmentData(data)
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          toasts(response.data.message, "Error")
          setDepartmentData({ departmentId: "", departmentNameName: response.data.message });
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function getMonitorList() {
    getMonitor(
      (response) => {
        if (response.data.success) {
          let data = response.data.data.monitor;
          if (data != undefined) {
            setMonitorData(data)
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          toasts(response.data.message, "Error")
          setDepartmentData({ monitorId: "", monitorName: response.data.message });
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  const [latestVideoData, setLatestVideoData] = useState<any>([])

  function getMonitorVideo() {

    checkLatestVideos()

    getMonitorVideoList(monitorId,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.monitorVideoList[0];
          if (data != undefined) {
            setLatestVideoData(data)
            getMonitorVideosList()
            // if (lastItem != undefined) {
            //   let la = lastItem

            // if ((la.videoName != data.videoName) && (la.monitorId == data.monitorId)) {
            //   window.location.reload()
            // } 

            console.log(data.statusId);
            

            if (data.statusId == 0) {
              window.location.reload()
            }
            // else {
            //   getMonitorVideosList()
            // }
            // }
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          // toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          // toasts(errorDescription, "Error")
        }
      }, error => {
        // toasts(error, "Error")
      }
    )
  }

  function getMonitorVideosList() {

    getMonitorVideos(monitorId,
      (response) => {
        if (response.data.success) {
          let monitorVideoData = response.data.data.monitorVideos;
          if (monitorVideoData != undefined) {

            let videoData: any = []

            if (monitorVideoData != undefined) {

              monitorVideoData.forEach((item: any) => {
                const obj = {
                  videoName: item.videoName,
                  departmentId: item.departmentId == 0 ? 1 : item.departmentId,
                  departmentName: item.departmentName,
                  monitorId: item.monitorId == 0 ? monitorId : item.monitorId,
                  monitorName: item.monitorName,
                  videoUrl: `${getAPIURL()}videos/${item.videoName}`
                };
                videoData.push(obj);
              });

              if (videoData != '') {

                let selectedUrl = videoData.filter((item: any) => item.monitorId == monitorId)
                let lastItem: any = selectedUrl.slice(-1)[0]

                if (lastItem != undefined) {
                  setVideoUrl(lastItem?.videoUrl);
                  // getMonitorVideo(lastItem)
                }
              }
            }
            else {
              // console.log('data undefined');              
            }
          } else {
            // toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          setNoDataMsg(response.data.message)
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          // toasts(errorDescription, "Error")
        }
      }, error => {
        // toasts(error, "Error")
      }
    )
  }

  function checkLatestVideos() {
    setTimeout(getMonitorVideo, 4000);
  }

  return (
    <PageWrapper title='TV'>
      <Page container='fluid'>
        <div className='col-md-12 d-flex justify-content-center' style={{ height: '80%' }}>
          <video ref={videoRef} width="100%" controls muted loop onClick={() => setIsPlaying(!isPlaying)}>
            {videoUrl != null ? <source src={videoUrl} type="video/mp4" /> : null}
            Your browser does not support the video tag.
          </video>
        </div>

        {/* <div className='col-md-12 d-flex justify-content-center mt-3'>
          <ButtonGroup>
            <Button color='primary' isOutline onClick={handlePlayPause}>
              {isPlaying ? 'Pause' : 'Play'}
            </Button>
            <Button color='primary' isOutline onClick={() => handleSkip(10)}>
              Skip +10s
            </Button>
            <Button color='primary' isOutline onClick={() => handleSkip(-10)}>
              Skip -10s
            </Button>
          </ButtonGroup>
        </div> */}
      </Page>
    </PageWrapper>
  );
};

export default OpenVideo;