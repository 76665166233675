import dayjs from "dayjs";
import service from "../api/api.services";
import { AxiosResponse } from "axios";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getProductHistoryByUserAccountId = (clientInfoId: number, productHistoryId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getProductHistoryByUserAccountId/${getLicenseKey}/${clientInfoId}/${productHistoryId}`,
        response => {
            success(response)
        },
        error => {
            failure("getProductHistoryByUserAccountId - " + error.message)
            console.log('error caught in service : getProductHistoryByUserAccountId')
        },
    );

export const addProductHistory = (productPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addProductHistory`, productPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addProductHistory')
        }
    );


export const getProductList = (clientInfoId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getProductList/${getLicenseKey}/${clientInfoId}`,
        response => {
            success(response)
        },
        error => {
            failure("getProductList - " + error.message)
            console.log('error caught in service : getProductList')
        },
    );