import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import AuthContext from '../../../contexts/authContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { screensMenu } from '../../../menu';
import { getAllScreens, getScreens, getScreensForDown } from '../../../services/common.service';
import { toasts } from '../../../services/toaste.service';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);
	const { darkModeStatus } = useDarkMode();
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const navigate = useNavigate();
	const [directScreenGetMenu, setDirectScreenGetMenu] = useState<any>({});
	const [groupScreenMenu, setGroupScreenMenu] = useState<any>({});
	const [downScreensMenu, setDownScreensMenu] = useState<any>({});

	// const [screen, setScreen] = useState<any>('');
	// const [screen, setScreen] = useState<any>('');
	// const [screen, setScreen] = useState<any>('');

	useEffect(() => {
		if (userAccountId == '') {
			// navigate(`../login`);
			setGroupScreenMenu({})
			setDirectScreenGetMenu({})
			setDownScreensMenu({})
		}
		else {
			getAllScreensList()
			getScreensList()
			getDownScreensList()
		}
	}, []);

	function getAllScreensList() {
		getAllScreens(userTypeId, userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.allScreens;
					if (data != undefined) {
						generateMenu(data)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function generateMenu(screenData: any) {
		let menu: any = {};
		for (let i = 0; i < screenData.length; i++) {

			let screenGroupMain = screenData[i]

			let submenu: any = {}
			menu[screenGroupMain.screenGroupDisplayName] = {
				id: screenGroupMain.screenGroupDisplayName,
				text: screenGroupMain.screenGroupDisplayName,
				path: screenGroupMain.screenGroupDisplayName,
				icon: screenGroupMain.screenGroupIcon,
			}
			for (let k = 0; k < screenGroupMain.screens.length; k++) {
				let screen = screenGroupMain.screens[k]
				submenu[screen.displayName] = {
					id: screen.displayName,
					text: screen.displayName,
					path: screen.screenPath,
					icon: screen.screenIcon,
				}
			}
			menu[screenGroupMain.screenGroupDisplayName]['subMenu'] = submenu
		}
		setGroupScreenMenu(menu)
	}

	function getScreensList() {
		getScreens(userTypeId, userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.screens;
					if (data != undefined) {
						generateScreenMenu(data)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function generateScreenMenu(screenData: any) {

		let menu: any = {};
		for (let i = 0; i < screenData.length; i++) {

			let screenGroupMain = screenData[i]
			menu[screenGroupMain.displayName] = {
				id: screenGroupMain.displayName,
				text: screenGroupMain.displayName,
				path: screenGroupMain.screenPath,
				icon: screenGroupMain.screenIcon,
			}
		}
		setDirectScreenGetMenu(menu)
	}

	function getDownScreensList() {
		getScreensForDown(userTypeId, userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.screens;
					if (data != undefined) {
						generateDownScreenMenu(data)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function generateDownScreenMenu(screenData: any) {

		let menu: any = {};
		for (let i = 0; i < screenData.length; i++) {

			let screenGroupMain = screenData[i]
			menu[screenGroupMain.displayName] = {
				id: screenGroupMain.displayName,
				text: screenGroupMain.displayName,
				path: screenGroupMain.screenPath,
				icon: screenGroupMain.screenIcon,
			}
		}
		setDownScreensMenu(menu)
	}	

	const getMenu = { ...directScreenGetMenu, ...groupScreenMenu, ...downScreensMenu };	

	const location = useLocation();
	const currentPath = location.pathname;

	const parts = currentPath.split('/tv');
	const checkCurrentPath = parts[0];

	return (
		<>
			{checkCurrentPath != '' ?
				<Aside>
					<AsideHead>
						<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
					</AsideHead>
					<AsideBody>
						{!doc && (
							<>
								<Navigation menu={getMenu} id='aside-menu' />
								{/* <NavigationLine /> */}
							</>
						)}
					</AsideBody>
					<AsideFoot>
						<User />
					</AsideFoot>
				</Aside> : null
			}
		</>
	);
};

export default DefaultAside;
