import React from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';
import { useLocation } from 'react-router-dom';

const DefaultFooter = () => {
	const { darkModeStatus } = useDarkMode();

	const location = useLocation();
	const currentPath = location.pathname;

	const parts = currentPath.split('/tv');
	const checkCurrentPath = parts[0];
	
	return (
		<>
			{checkCurrentPath != '' ?
				<Footer>
					<div className='container-fluid'>
						<div className='row'>
							<div className='col'>
								<span className='fw-light'>Copyright © 2024 - All rights reserved.</span>
							</div>
							<div className='col-auto'>
								<a
									href='/'
									className={classNames('text-decoration-none', {
										'link-dark': !darkModeStatus,
										'link-light': darkModeStatus,
									})}>
									<small className='fw-bold'></small>
								</a>
							</div>
						</div>
					</div>
				</Footer> : null
			}
		</>
	);
};

export default DefaultFooter;
