import dayjs from "dayjs";
import service from "../api/api.services";
import { AxiosResponse } from "axios";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()


export const getClientList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getClientList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getClientList - " + error.message)
            console.log('error caught in service : getClientList')
        },
    );

export const getDepartmentList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDepartmentList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDepartmentList - " + error.message)
            console.log('error caught in service : getDepartmentList')
        },
    );

export const getMonitor = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getMonitor/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getMonitor - " + error.message)
            console.log('error caught in service : getMonitor')
        },
    );

export const getMonitorVideoList = (departmentId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getMonitorVideoList/${getLicenseKey}/${departmentId}`,
        response => {
            success(response)
        },
        error => {
            failure("getMonitorVideoList - " + error.message)
            console.log('error caught in service : getMonitorVideoList')
        },
    );

export const getAllScreens = (userTypeId: any, userAccountId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getAllScreens/${getLicenseKey}/${userTypeId}/${userAccountId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllScreens - " + error.message)
            console.log('error caught in service : getAllScreens')
        },
    );

export const getScreens = (userTypeId: any, userAccountId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getScreens/${getLicenseKey}/${userTypeId}/${userAccountId}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getScreens - " + error.message)
            console.log('error caught in service : getScreens')
        },
    );

export const getScreensForDown = (userTypeId: any, userAccountId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getScreens/${getLicenseKey}/${userTypeId}/${userAccountId}/1`,
        response => {
            success(response)
        },
        error => {
            failure("getScreens - " + error.message)
            console.log('error caught in service : getScreens')
        },
    );

export const getSubModuleType = (moduleTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getSubModuleType/${getLicenseKey}/${moduleTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getSubModuleType - " + error.message)
            console.log('error caught in service : getSubModuleType')
        },
    );

export const addSubModuleType = (subModuleTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addSubModuleType`, subModuleTypePostData,
        response => {
            success(response)
        },
        error => {
            failure("addSubModuleType - " + error.message)
            console.log('error caught in service : addSubModuleType')
        }
    );

export const getUserProfile = (userAccountId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getUserProfile/${getLicenseKey}/${userAccountId}`,
        response => {
            success(response)
        },
        error => {
            failure("getUserProfile - " + error.message)
            console.log('error caught in service : getUserProfile')
        },
    );


// Convert Date To Time
export function convertDateToEpoch(fromDate: any) {
    let newFromDate = new Date(fromDate).getTime();
    return newFromDate;
}

// Get Current Time
export function getCurrentDateAndTime(type: any) {
    const today = type == 'date' ? dayjs(new Date()).format('YYYY-MM-DD') : dayjs(new Date()).format('D MMMM, YYYY h:mm A')
    return today;
}

// Date Range
export function convertDateRangeToEpoch(fromDate: any, toDate: any) {
    return Array(new Date(fromDate).getTime(), new Date(toDate).getTime());
}

// Convert Date To Words
export function convertDateToWords(date: any) {


    const toWords = (num: number): string => {
        const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        if (num === 0) return 'Zero';

        if (num >= 1000 && num <= 9999) {
            const thousandsDigit = Math.floor(num / 1000);
            const remainder = num % 1000;
            return `${ones[thousandsDigit]} Thousand${remainder !== 0 ? ` ${toWords(remainder)}` : ''}`;
        }

        if (num >= 100 && num <= 999) {
            const hundredsDigit = Math.floor(num / 100);
            const remainder = num % 100;
            return `${ones[hundredsDigit]} Hundred${remainder !== 0 ? ` ${toWords(remainder)}` : ''}`;
        }

        if (num >= 10 && num <= 19) return teens[num - 10];
        if (num >= 20 && num <= 99) {
            const tensDigit = Math.floor(num / 10);
            const onesDigit = num % 10;
            return `${tens[tensDigit]}${onesDigit !== 0 ? ` ${ones[onesDigit]}` : ''}`;
        }

        if (num >= 1 && num <= 9) return ones[num];

        return '';
    };

    const dateParts = date.split('-' || '/');

    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[2]);

    const yearInWords = toWords(year);
    const monthInWords = toWords(month);
    const dayInWords = toWords(day);

    const dateOfBirthInWord = `${dayInWords} - ${monthInWords} - ${yearInWords}`;
    return dateOfBirthInWord;
};

// only Allow Number
export function onlyAllowNumber(e: any) {

    if (e.key === 'Backspace') {
        return;
    }
    if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
    }
}

// Filter
export function updateFilter(data: any, columnVisibilityData: any, form: any) {

    const filteredData = data.filter((rowData: any) => {
        for (const column of columnVisibilityData) {
            const columnValue = rowData[column.keyName];
            // if (columnValue !== null && columnValue.toString().toLowerCase().includes(form.values.searchInput.toLowerCase())) {
            //     return true;
            // }
            if (columnValue && columnValue !== null && columnValue.toString().toLowerCase().includes(form.values.searchInput.toLowerCase())) {
                return true;
            }
        }
        return false;
    });

    return filteredData;

}

export function onFileSelected(event: any, setSelectedFile: any) {
    const file = event.target.files[0];
    const maxSizeInBytes = 100 * 1024; // 100KB

    if (file && file.size > maxSizeInBytes) {
        setSelectedFile(file);
    } else {
        event.preventDefault();
        alert('Please select a file greater than 100KB.');
    }

}

/// Select style
export const customStyles = {
    control: (provided: any) => ({
        ...provided,
        fontWeight: 600,
        border: 'transparent',
        backgroundColor: 'transparent',
        boxShadow: 'transparent',
        position: 'absolute',
        top: '10px',
        left: '2px',
        width: '100%',
        fontSize: '1rem',
    }),
    isClearable: (provided: any) => ({
        ...provided,
        color: 'red'
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        cursor: "pointer",
        lineHeight: '1.5',
        minHeight: '1.2em',
        fontWeight: '500',
        textShadow: "none",
        padding: '1px 15px 1px',
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: 2,
        fontFamily: 'inherit',
    }),
    menu: (provided: any) => ({
        ...provided,
        zIndex: 9999, // Increase zIndex to ensure it appears on top of other content
        overflowY: "auto",
        position: 'absolute',
        // maxHeight: "250px",
        // backgroundColor: '#f8f9fa',
        fontFamily: 'inherit',
        fontWeight: 'normal',
        // textShadow: "none",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: () => ({ display: "none" }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        pointerEvents: 'none',
        color: 'black',
        fontSize: '13px',
        lineHeight: 1,
        paddingBottom: '0.155rem',
        fontFamily: 'inherit'
    })
};

export const customStylesForCanvas = {
    control: (provided: any) => ({
        ...provided,
        width: '100%',
        fontSize: '1rem',
        fontWeight: 600,
        lineWeight: 1.5,
        color: '#323232',
        appearance: 'none',
        backgroundColor: 'transparent',
        border: 'transparent',
        borderRadius: '1rem',
        minHeight: '0px',
        borderStyle: '0px',
        boxShadow: 'transparent',
        padding: 0
    }),
    isClearable: (provided: any) => ({
        ...provided,
        color: 'red'
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        cursor: "pointer",
        lineHeight: '1.5',
        minHeight: '1.2em',
        fontWeight: '500',
        textShadow: "none",
        padding: '1px 15px 1px',
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: 2,
        fontFamily: 'inherit',
    }),
    menu: (provided: any) => ({
        ...provided,
        zIndex: 9999, // Increase zIndex to ensure it appears on top of other content
        overflowY: "auto",
        position: 'absolute',
        // maxHeight: "250px",
        // backgroundColor: '#f8f9fa',
        fontFamily: 'inherit',
        fontWeight: 'normal',
        // textShadow: "none",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: () => ({ display: "none" }),
    // dropdownIndicator: (provided: any, state: any) => ({
    //     ...provided,
    //     fontWeight: 50,
    //     color : 'grey',
    //     position: 'relative',
    //     top: '-6px',
    //     left :'0'
    // }),
    // placeholder: (provided: any, state: any) => ({
    //     ...provided,
    //     // position: 'absolute',
    //     pointerEvents: 'none',
    //     color: 'black',
    //     fontSize: '13px',
    //     lineHeight: 1,
    //     // left: '0',
    //     // paddingTop: '0rem',
    //     paddingBottom: '0.155rem',
    //     // zIndex: 1,
    //     fontFamily: 'inherit'

    // })
};