import dayjs from "dayjs";
import service from "../api/api.services";
import { AxiosResponse } from "axios";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getBoxLabel = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBoxLabel/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getBoxLabel - " + error.message)
            console.log('error caught in service : getBoxLabel')
        },
    );

export const addBoxLabel = (boxLabelPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addBoxLabel`, boxLabelPostData,
        response => {
            success(response)
        },
        error => {
            failure("addBoxLabel - " + error.message)
            console.log('error caught in service : addBoxLabel')
        }
    );

export const getLotDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLotDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getLotDetails - " + error.message)
            console.log('error caught in service : getLotDetails')
        },
    );

export const addLotDetails = (lotDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addLotDetails`, lotDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure("addLotDetails - " + error.message)
            console.log('error caught in service : addLotDetails')
        }
    );

export const getGenerateQrDetails = (lotDetailsId: any, isDropdown: any, isDispatch: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getGenerateQrDetails/${getLicenseKey}/${lotDetailsId}/${isDropdown}/${isDispatch}`,
        response => {
            success(response)
        },
        error => {
            failure("getGenerateQrDetails - " + error.message)
            console.log('error caught in service : getGenerateQrDetails')
        },
    );

export const addGenerateQrDetails = (generateQrDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addGenerateQrDetails`, generateQrDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure("addGenerateQrDetails - " + error.message)
            console.log('error caught in service : addGenerateQrDetails')
        }
    );

export const getBoxLabelQrDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBoxLabelQrDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getBoxLabelQrDetails - " + error.message)
            console.log('error caught in service : getBoxLabelQrDetails')
        },
    );

export const getScannedQrDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getScannedQrDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getScannedQrDetails - " + error.message)
            console.log('error caught in service : getScannedQrDetails')
        },
    );

export const updateScannedQrStatus = (scannedQrPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateScannedQrStatus`, scannedQrPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateScannedQrStatus - " + error.message)
            console.log('error caught in service : updateScannedQrStatus')
        }
    );

export const addGenerateBarcodeDetails = (generateBarcodePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addGenerateBarcodeDetails`, generateBarcodePostData,
        response => {
            success(response)
        },
        error => {
            failure("addGenerateBarcodeDetails - " + error.message)
            console.log('error caught in service : addGenerateBarcodeDetails')
        }
    );

export const getGenerateBarcodeDetails = (lotDetailsId: any, isDropdown: any, isDispatch: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getGenerateBarcodeDetails/${getLicenseKey}/${lotDetailsId}/${isDropdown}/${isDispatch}`,
        response => {
            success(response)
        },
        error => {
            failure("getGenerateBarcodeDetails - " + error.message)
            console.log('error caught in service : getGenerateBarcodeDetails')
        },
    );

export const getBundleLabel = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBundleLabel/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getBundleLabel - " + error.message)
            console.log('error caught in service : getBundleLabel')
        },
    );

export const addBundleLabel = (bundleLabelPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addBundleLabel`, bundleLabelPostData,
        response => {
            success(response)
        },
        error => {
            failure("addBundleLabel - " + error.message)
            console.log('error caught in service : addBundleLabel')
        }
    );

export const getScannedBarcodeDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getScannedBarcodeDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getScannedBarcodeDetails - " + error.message)
            console.log('error caught in service : getScannedBarcodeDetails')
        },
    );

export const getBundleLabelBarcodeDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBundleLabelBarcodeDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getBundleLabelBarcodeDetails - " + error.message)
            console.log('error caught in service : getBundleLabelBarcodeDetails')
        },
    );

export const getDispatchBoxInfo = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDispatchBoxInfo/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDispatchBoxInfo - " + error.message)
            console.log('error caught in service : getDispatchBoxInfo')
        },
    );

export const addDispatchBoxInfo = (dispatchBoxPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addDispatchBoxInfo`, dispatchBoxPostData,
        response => {
            success(response)
        },
        error => {
            failure("addDispatchBoxInfo - " + error.message)
            console.log('error caught in service : addDispatchBoxInfo')
        }
    );

export const getDispatchBaleInfo = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDispatchBaleInfo/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDispatchBaleInfo - " + error.message)
            console.log('error caught in service : getDispatchBaleInfo')
        },
    );

export const addDispatchBaleInfo = (dispatchBalePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addDispatchBaleInfo`, dispatchBalePostData,
        response => {
            success(response)
        },
        error => {
            failure("addDispatchBaleInfo - " + error.message)
            console.log('error caught in service : addDispatchBaleInfo')
        }
    );

export const getNextCartonNo = (generateQrDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getNextCartonNo/${getLicenseKey}/${generateQrDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getNextCartonNo - " + error.message)
            console.log('error caught in service : getNextCartonNo')
        },
    );

export const getNextNoOfBundle = (generateBarcodeDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getNextNoOfBundle/${getLicenseKey}/${generateBarcodeDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getNextNoOfBundle - " + error.message)
            console.log('error caught in service : getNextNoOfBundle')
        },
    );

export const getChallanDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getChallanDetails/${getLicenseKey}/0/0`,
        response => {
            success(response)
        },
        error => {
            failure("getChallanDetails - " + error.message)
            console.log('error caught in service : getChallanDetails')
        },
    );