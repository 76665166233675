import { useContext, useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Button, { ButtonGroup } from "../bootstrap/Button";
import FormGroup from "../bootstrap/forms/FormGroup";
import AlertService from "../../services/alert.service";
import Collapse from "../bootstrap/Collapse";
import Input from "../bootstrap/forms/Input";
import useDarkMode from "../../hooks/useDarkMode";
import PaginationButtons, { dataPagination } from "../PaginationButtons";
import useSelectTable from "../../hooks/useSelectTable";
import { useFormik } from "formik";
import useSortableData from "../../hooks/useSortableData";
import classNames from "classnames";
import { toasts } from "../../services/toaste.service";
import { addBoxLabel, addDispatchBoxInfo, getBoxLabel, getDispatchBoxInfo, getGenerateQrDetails, getLotDetails } from "../../services/packingService";
import Icon from "../icon/Icon";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../bootstrap/OffCanvas";
import { TableLoader, showLoader } from "../../services/loader.services";
import NoDataMsg from "../../common/components/NoDataMsg";
import { getLicenseKey } from "../../services/application.settings";
import AuthContext from "../../contexts/authContext";
import Select from "../bootstrap/forms/Select";

function BoxDispatch() {

    useEffect(() => {
        getDispatchBoxInfoList()
        getGenerateQrDetailsList()
    }, [])

    const dispatchBoxInfoForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            generateQrDetailsId: '',
            companyName: '',
            noOfBox: '',
            lotNo: '',
        },
        validate: (values: any) => {
            const errors: {
                generateQrDetailsId?: string;
                companyName?: string;
                noOfBox?: string;
                lotNo?: string;
            } = {};
            if (!generateQrDetailsId) {
                errors.generateQrDetailsId = 'Required';
            }
            if (!values.companyName) {
                errors.companyName = 'Required';
            }
            if (!values.noOfBox) {
                errors.noOfBox = 'Required';
            }
            if (!lotNo) {
                errors.lotNo = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addDispatchBoxInfoSubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [dispatchBoxInfoOffcanvas, setDispatchBoxInfoOffcanvas] = useState(false);

    const [dispatchBoxInfo, setDispatchBoxInfo] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')
    const [dataStatus, setDataStatus] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(dispatchBoxInfo);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // Name           
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.countNo !== null && i.countNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.companyName !== null && i.companyName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.noOfBox !== null && i.noOfBox.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    const [lotNo, setLotNo] = useState<any>('')
    const [generateQrDetailsId, setGenerateQrDetailsId] = useState<any>('')

    const selectLotNo = (e: any) => {
        let selectedData: any = generateQrDetailsData.find((data: any) => data.lotNo == e.target.value);
        setLotNo(selectedData.lotNo)
        getGenerateQrDetailsListForDropdown(selectedData.lotId)
    };

    const selectCountNo = (e: any) => {
        let selectedData: any = countNoData.find((data: any) => data.generateQrDetailsId == e.target.value);
        setGenerateQrDetailsId(selectedData.generateQrDetailsId)
    };

    function getDispatchBoxInfoList() {
        setDataStatus(false)
        getDispatchBoxInfo(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dispatchBoxInfo;
                    if (data != undefined) {
                        setDispatchBoxInfo(data)
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataStatus(true)
                }
            }, error => {
                toasts(error, "Error")
                setDataStatus(true)
            }
        )
    }

    function setDispatchBoxDetails() {
        return ({
            licenseKey: getLicenseKey,
            generateQrDetailsId: generateQrDetailsId,
            companyName: dispatchBoxInfoForm.values.companyName,
            noOfBox: dispatchBoxInfoForm.values.noOfBox,
            userAccountId: userAccountId,
        })
    }

    function addDispatchBoxInfoSubmit() {
        showLoader(true)
        if (dispatchBoxInfoForm.isValid) {
            let dispatchBoxPostData = setDispatchBoxDetails()
            addDispatchBoxInfo(dispatchBoxPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getDispatchBoxInfoList()
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (dispatchBoxInfoForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please Select Video!', type: "error" });
            setIsOpen(true);
        }
    }

    const [generateQrDetailsData, setGenerateQrDetailsData] = useState([])

    function getGenerateQrDetailsList() {
        getGenerateQrDetails(0, 1, 1,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.generateQrDetails;
                    if (data != undefined) {
                        setGenerateQrDetailsData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [countNoData, setCountNoData] = useState([])

    function getGenerateQrDetailsListForDropdown(lotDetailsId: any) {
        getGenerateQrDetails(lotDetailsId, 1, 1,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.generateQrDetails;
                    if (data != undefined) {
                        setCountNoData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
        dispatchBoxInfoForm.resetForm()
        setGenerateQrDetailsId('')
        setDispatchBoxInfoOffcanvas(false)
        setLotNo('')
    }

    return (
        <>
            <PageWrapper title={`Box Dispatch`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-7">
                                <CardTitle tag='div' className='h5'>Dispatch - Box</CardTitle>
                            </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Button color='primary' icon='Add' isLight onClick={() => setDispatchBoxInfoOffcanvas(true)}>
                                    Assign
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')} style={{ width: '18%' }}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('lotNo')} style={{ width: '18%' }}
                                                className='cursor-pointer text-decoration-underline'>Lot No{' '}
                                                <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('countNo')} style={{ width: '18%' }}
                                                className='cursor-pointer text-decoration-underline'>Count No{' '}
                                                <Icon size='lg' className={getClassNamesFor('countNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('companyName')} style={{ width: '18%' }}
                                                className='cursor-pointer text-decoration-underline'>Company Name{' '}
                                                <Icon size='lg' className={getClassNamesFor('companyName')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('noOfBox')} style={{ width: '18%' }}
                                                className='cursor-pointer text-decoration-underline'>No Of Box{' '}
                                                <Icon size='lg' className={getClassNamesFor('noOfBox')} icon='FilterList' />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{dispatchBoxInfo != "" ?<>{filteredData.map((item: any) => (<tr key={item.sno}><th>{item.sno}</th><th>{item.lotNo}</th><th>{item.countNo}</th><th>{item.companyName}</th><th>{item.noOfBox}</th></tr>))}</>:<NoDataMsg columnsCount={10} msg={noDataMsg} />}</tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>

                    <OffCanvas setOpen={setDispatchBoxInfoOffcanvas} isOpen={dispatchBoxInfoOffcanvas} titleId='dispatchBoxInfoOffcanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={dispatchBoxInfoForm.handleSubmit} isNotClose>
                        <OffCanvasHeader setOpen={closeAndReset}>
                            <OffCanvasTitle id='dispatchBoxInfoOffcanvas'>Dispatch - Assign Box</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className='row g-4'>
                                <div className='col-12'>
                                    <FormGroup id='lotNo' label='Lot No'>
                                        <Select
                                            ariaLabel='Lot No'
                                            placeholder="Select Lot No"
                                            onChange={selectLotNo}
                                            value={lotNo}
                                            isValid={dispatchBoxInfoForm.isValid}
                                            onBlur={dispatchBoxInfoForm.handleBlur}
                                            isTouched={dispatchBoxInfoForm.touched.lotNo}
                                            invalidFeedback={dispatchBoxInfoForm.errors.lotNo}
                                            list={generateQrDetailsData.map((data: any) => (
                                                { value: data.lotNo, text: data.lotNo }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='generateQrDetailsId' label='Count No'>
                                        <Select
                                            ariaLabel='Count No'
                                            placeholder="Select Count No"
                                            onChange={selectCountNo}
                                            value={generateQrDetailsId}
                                            isValid={dispatchBoxInfoForm.isValid}
                                            onBlur={dispatchBoxInfoForm.handleBlur}
                                            isTouched={dispatchBoxInfoForm.touched.generateQrDetailsId}
                                            invalidFeedback={dispatchBoxInfoForm.errors.generateQrDetailsId}
                                            list={countNoData.map((data: any) => (
                                                { value: data.generateQrDetailsId, text: data.countNo }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='companyName' label='Company Name'>
                                        <Input onChange={dispatchBoxInfoForm.handleChange} value={dispatchBoxInfoForm.values.companyName}
                                            isValid={dispatchBoxInfoForm.isValid}
                                            onBlur={dispatchBoxInfoForm.handleBlur}
                                            isTouched={dispatchBoxInfoForm.touched.companyName}
                                            invalidFeedback={dispatchBoxInfoForm.errors.companyName}
                                            placeholder="Enter Company Name" />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='noOfBox' label='No Of Box'>
                                        <Input onChange={dispatchBoxInfoForm.handleChange} value={dispatchBoxInfoForm.values.noOfBox}
                                            isValid={dispatchBoxInfoForm.isValid}
                                            onBlur={dispatchBoxInfoForm.handleBlur}
                                            isTouched={dispatchBoxInfoForm.touched.noOfBox}
                                            invalidFeedback={dispatchBoxInfoForm.errors.noOfBox}
                                            placeholder="Enter No Of Box" />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='info' className='w-100' type="submit" icon="Save" isDisable={!dispatchBoxInfoForm.isValid && !!dispatchBoxInfoForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper >
        </>
    )
}
export default BoxDispatch;