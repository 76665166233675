import React, { lazy, useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import AuthContext from '../../contexts/authContext';
import Login from '../../pages/presentation/auth/Login';
import AddProduct from '../../components/product/AddProduct';
import { getAllScreens, getMonitor, getScreens, getScreensForDown } from '../../services/common.service';
import { toasts } from '../../services/toaste.service';
import OpenVideo from '../../components/upload/OpenVideo';
import UnauthorizedPage from '../../pages/presentation/auth/UnauthorizedPage';
import LoaderComponent from '../../pages/presentation/auth/LoaderComponent';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';

const ContentRoutes = () => {

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const location = useLocation();
	const currentPath = location.pathname;

	useEffect(() => {
		getMonitorList()
		if (userAccountId != '') {
			getAllScreensList()
			getScreensList()
			getDownScreensList()
		}
		else {
			setGroupScreensMenu([])
			setUpScreensMenu([])
			setDownScreensMenu([])
			setTvData([])
		}
	}, [userAccountId]);

	const [tvData, setTvData] = useState<any>([])
	const [groupScreensMenu, setGroupScreensMenu] = useState<any>([])
	const [upScreensMenu, setUpScreensMenu] = useState<any>([])
	const [downScreensMenu, setDownScreensMenu] = useState<any>([])

	function getMonitorList() {
		getMonitor(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.monitor;
					if (data != undefined) {

						let tvData: any = []

						for (let i = 0; i < data.length; i++) {
							const obj = {
								path: `tv${data[i].monitorId}`,
								element: <OpenVideo monitorId={data[i].monitorId} />
							}
							tvData.push(obj)
							contents.push(obj)
						}
						setTvData(tvData)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getAllScreensList() {
		getAllScreens(userTypeId, userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.allScreens;
					if (data != undefined) {
						generateMenuFinal(data)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getScreensList() {
		getScreens(userTypeId, userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.screens;
					if (data != undefined) {
						generateMenuFinal1(data)

					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getDownScreensList() {
		getScreensForDown(userTypeId, userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.screens;
					if (data != undefined) {
						generateMenuFinal2(data)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function generateMenuFinal(screenData: any) {
		let menu: any = [];

		for (let i = 0; i < screenData.length; i++) {
			let screenGroup = screenData[i]
			for (let k = 0; k < screenGroup.screens.length; k++) {
				let screen = screenGroup.screens[k]

				for (let l = 0; l < contents.length; l++) {

					let page = contents[l]

					if (screen.screenPath == page.path) {
						menu.push({
							path: screen.screenPath,
							element: page.element,
						})
					}
				}
			}
		}
		menu = menu
		setGroupScreensMenu(menu)
	}

	function generateMenuFinal1(screenData: any) {

		let menu: any = [];

		for (let i = 0; i < screenData.length; i++) {
			let screenGroup = screenData[i]

			for (let l = 0; l < contents.length; l++) {

				let page = contents[l]

				if (screenGroup.screenPath == page.path) {
					menu.push({
						path: screenGroup.screenPath,
						element: page.element,
					})
				}
			}
		}
		menu = menu
		setUpScreensMenu(menu)
	}

	function generateMenuFinal2(screenData: any) {

		let menu: any = [];

		for (let i = 0; i < screenData.length; i++) {
			let screenGroup = screenData[i]

			for (let l = 0; l < contents.length; l++) {

				let page = contents[l]

				if (screenGroup.screenPath == page.path) {
					menu.push({
						path: screenGroup.screenPath,
						element: page.element,
					})
				}
			}
		}
		menu = menu
		setDownScreensMenu(menu)
	}

	const getMenu: any = [...groupScreensMenu, ...upScreensMenu, ...downScreensMenu]
	const screen: any = userAccountId > 0 ? getMenu : ""
	const screen1: any = [...getMenu, ...tvData]

	return (
		<Routes>
			{userAccountId != "" && currentPath != '/login' ?
				<>
					{groupScreensMenu != "" && upScreensMenu != "" && downScreensMenu != "" ?
						<>
							{screen1.map((page: any) => (
								<Route key={page.path} {...page} />
							))}
						</> :
						<>
							{tvData == '' ? <> <Route path='*' element={<LoaderComponent />} /></> : <>
								{contents.map((page: any) => (
									<Route key={page.path} {...page} />
								))}</>
							}
						</>
					}
				</> : tvData == "" ? <><Route path='*' element={<LoaderComponent />} /></>
					: tvData != "" ?
						<>
							{contents.map((page: any) => (
								<Route key={page.path} {...page} />
							))}
						</> :
						<>
							<Route path='/login' element={<Login />} />
						</>
			}
			<Route path='*' element={<UnauthorizedPage />} />
			<Route path='/' element={<AddProduct />} />
		</Routes>
	);
};

export default ContentRoutes;
