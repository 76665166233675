import React, { SVGProps } from 'react';

const SvgRailwayAlert = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M4 16.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V13H4v3.5zm6-2.5c.83 0 1.5.67 1.5 1.5S10.83 17 10 17s-1.5-.67-1.5-1.5S9.17 14 10 14zM4.43 6H11c0-.33.03-.66.08-.98-3.37-.1-5.75.21-6.65.98z'
				opacity={0.3}
			/>
			<path d='M4 11V8h7.29C11.1 7.37 11 6.7 11 6H4.43c.9-.77 3.28-1.08 6.65-.98.1-.7.3-1.37.59-1.99C2.97 2.67 2 5.02 2 7v9.5C2 18.43 3.57 20 5.5 20L4 21v1h12v-1l-1.5-1c1.93 0 3.5-1.57 3.5-3.5V13c-1.91 0-3.63-.76-4.89-2H4zm12 5.5c0 .83-.67 1.5-1.5 1.5h-9c-.83 0-1.5-.67-1.5-1.5V13h12v3.5z' />
			<circle cx={10} cy={15.5} r={1.5} />
			<path d='M18 1c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm.5 8h-1V8h1v1zm0-2h-1V3h1v4z' />
		</svg>
	);
};

export default SvgRailwayAlert;
