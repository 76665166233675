import React, { useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import { demoPagesMenu } from '../../menu';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import ThemeContext from '../../contexts/themeContext';
import Avatar from '../../components/Avatar';
import UserImageWebp from '../../assets/img/wanna/wanna1.webp';
import UserImage from '../../assets/img/wanna/wanna1.png';

const User = () => {
	const { width } = useWindowSize();
	const { setAsideStatus } = useContext(ThemeContext);
	const { userAccountId, userData, setUserData, setUserAccountId, userTypeId, setUserTypeId, setPreviousVideo } = useContext<any>(AuthContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	function closeAndReset() {
		setUserAccountId('')
		setUserTypeId('')
		setPreviousVideo('')
		setUserData([])
		localStorage.clear();
		navigate(`../${demoPagesMenu.login.path}`);
		if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) {
			setAsideStatus(false);
		}
	}

	return (
		<>
			{userTypeId != '10' ?
				<>
					<div
						className={classNames('user', { open: collapseStatus })}
						role='presentation'
						onClick={() => setCollapseStatus(!collapseStatus)}>
						<div className='user-avatar'>

							<Avatar
								srcSet={UserImageWebp}
								src={UserImage}
								size={48}
								color='primary'
							/>

						</div>
						<div className='user-info'>
							<div className='user-name d-flex align-items-center'>
								{userData.userName}
								<Icon icon='Verified' className='ms-1' color='info' size='lg' />
							</div>
							<div className='user-sub-title'>{userData.userType}</div>
						</div>
					</div>
					<DropdownMenu>
						{/* <DropdownItem>
							<Button icon='AccountBox' onClick={() => navigate(`../academic/staffProfile/${userAccountId}`)}>
								Profile
							</Button>
						</DropdownItem> */}
						<DropdownItem>
							<Button
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								aria-label='Toggle fullscreen'>
								{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
							</Button>
						</DropdownItem>
					</DropdownMenu>

					<Collapse isOpen={collapseStatus} className='user-menu'>
						<nav aria-label='aside-bottom-user-menu'>
							<div className='navigation'>
								{/* <div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={() =>
										navigate(`../academic/staffProfile/${userAccountId}`)

									}>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<Icon icon='AccountBox' className='navigation-icon' />
											<span className='navigation-text'>
												{t('menu:Profile') as ReactNode}
											</span>
										</span>
									</span>
								</div> */}
								<div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={() => {
										setDarkModeStatus(!darkModeStatus);
										handleItem();
									}}>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<Icon
												icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
												color={darkModeStatus ? 'info' : 'warning'}
												className='navigation-icon'
											/>
											<span className='navigation-text'>
												{darkModeStatus
													? (t('menu:DarkMode') as ReactNode)
													: (t('menu:LightMode') as ReactNode)}
											</span>
										</span>
									</span>
								</div>
							</div>
						</nav>
						<NavigationLine />
						<nav aria-label='aside-bottom-user-menu-2'>
							<div className='navigation'>
								<div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={() => {
										if (setUserAccountId) {
											setUserAccountId('');
										}
										if (setUserTypeId) {
											setUserTypeId('');
										}
										if (setPreviousVideo) {
											setPreviousVideo('');
										}
										if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) {
											setAsideStatus(false);
										}
										localStorage.clear();
										navigate(`../${demoPagesMenu.login.path}`);
									}}>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<Icon icon='Logout' className='navigation-icon' />
											<span className='navigation-text'>
												{t('menu:Logout') as ReactNode}
											</span>
										</span>
									</span>
								</div>
							</div>
						</nav>
					</Collapse>
				</>
				:
				<>
					<div
						className={classNames('user', { open: collapseStatus })}
						role='presentation'
						onClick={() => setCollapseStatus(!collapseStatus)}>
						<div className='user-avatar'>
							{userData.profilePath ?
								<img
									srcSet={userData.profilePath}
									src={userData.profilePath}
									alt='Avatar'
									width={128}
									height={128}
								/> : <Avatar
									srcSet={UserImageWebp}
									src={UserImage}
									size={48}
									color='primary'
								/>}

						</div>
						<div className='user-info'>
							<div className='user-name d-flex align-items-center'>
								{`${userData.studentName}`}
								<Icon icon='Verified' className='ms-1' color='info' size='md' />
							</div>
							<div className='user-sub-title'>{userData.admissionNo}</div>
						</div>
					</div>
					<DropdownMenu>
						{/* <DropdownItem>
							<Button icon='AccountBox' onClick={() => navigate(`../academic/studentProfile/${userAccountId}`)}>
								Profile
							</Button>
						</DropdownItem> */}
						<DropdownItem>
							<Button
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								aria-label='Toggle fullscreen'>
								{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
							</Button>
						</DropdownItem>
					</DropdownMenu>

					<Collapse isOpen={collapseStatus} className='user-menu'>
						<nav aria-label='aside-bottom-user-menu'>
							<div className='navigation'>
								{/* <div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={() =>
										navigate(`../academic/studentProfile/${userAccountId}`)

									}>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<Icon icon='AccountBox' className='navigation-icon' />
											<span className='navigation-text'>
												{t('menu:Profile') as ReactNode}
											</span>
										</span>
									</span>
								</div> */}
								<div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={() => {
										setDarkModeStatus(!darkModeStatus);
										handleItem();
									}}>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<Icon
												icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
												color={darkModeStatus ? 'info' : 'warning'}
												className='navigation-icon'
											/>
											<span className='navigation-text'>
												{darkModeStatus
													? (t('menu:DarkMode') as ReactNode)
													: (t('menu:LightMode') as ReactNode)}
											</span>
										</span>
									</span>
								</div>
							</div>
						</nav>
						<NavigationLine />
						<nav aria-label='aside-bottom-user-menu-2'>
							<div className='navigation'>
								<div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={closeAndReset}>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<Icon icon='Logout' className='navigation-icon' />
											<span className='navigation-text'>
												{t('menu:Logout') as ReactNode}
											</span>
										</span>
									</span>
								</div>
							</div>
						</nav>
					</Collapse>
				</>
			}
		</>
	);
};

export default User;
