import { useContext, useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Button, { ButtonGroup } from "../bootstrap/Button";
import FormGroup from "../bootstrap/forms/FormGroup";
import AlertService from "../../services/alert.service";
import Collapse from "../bootstrap/Collapse";
import Input from "../bootstrap/forms/Input";
import useDarkMode from "../../hooks/useDarkMode";
import PaginationButtons, { dataPagination } from "../PaginationButtons";
import useSelectTable from "../../hooks/useSelectTable";
import { useFormik } from "formik";
import useSortableData from "../../hooks/useSortableData";
import classNames from "classnames";
import { toasts } from "../../services/toaste.service";
import { addBoxLabel, addBundleLabel, getBoxLabel, getBundleLabel, getGenerateBarcodeDetails, getGenerateQrDetails, getLotDetails, getNextNoOfBundle } from "../../services/packingService";
import Icon from "../icon/Icon";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../bootstrap/OffCanvas";
import { TableLoader, showLoader } from "../../services/loader.services";
import NoDataMsg from "../../common/components/NoDataMsg";
import { getLicenseKey } from "../../services/application.settings";
import AuthContext from "../../contexts/authContext";
import Select from "../bootstrap/forms/Select";

function AssignBundleCount() {

    useEffect(() => {
        getBundleLabelList()
        getGenerateBarcodeDetailsList()
    }, [])

    const assignBundleCountForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            generateBarcodeDetailsId: '',
            bundleCount: '',
            lotNo: '',
            crossWeight: '',
            netWeight: '',
        },
        validate: (values: any) => {
            const errors: {
                generateBarcodeDetailsId?: string;
                bundleCount?: string;
                lotNo?: string;
                crossWeight?: string;
                netWeight?: string;
            } = {};
            if (!generateBarcodeDetailsId) {
                errors.generateBarcodeDetailsId = 'Required';
            }
            if (!values.bundleCount) {
                errors.bundleCount = 'Required';
            }           
            if (!values.crossWeight) {
                errors.crossWeight = 'Required';
            }
            if (!values.netWeight) {
                errors.netWeight = 'Required';
            }
            if (!lotNo) {
                errors.lotNo = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addBundleLabelSubmit() },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { darkModeStatus } = useDarkMode();
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [addBundleLabelOffcanvas, setAddBundleLabelOffcanvas] = useState(false);

    const [bundleLabelData, setBundleLabelData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')
    const [dataStatus, setDataStatus] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(bundleLabelData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);


    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // Name           
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.countNo !== null && i.countNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.noOfBundle !== null && i.noOfBundle.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.crossWeight !== null && i.crossWeight.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.bundleCount !== null && i.bundleCount.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    const [lotNo, setLotNo] = useState<any>('')
    const [countNo, setCountNo] = useState<any>('')
    const [generateBarcodeDetailsId, setGenerateBarcodeDetailsId] = useState<any>('')

    const selectLotNo = (e: any) => {
        let selectedData: any = generateBarcodeDetailsData.find((data: any) => data.lotNo == e.target.value);
        setLotNo(selectedData.lotNo)
        getGenerateBarcodeDetailsForDropdown(selectedData.lotId)
    };

    const selectCountNo = (e: any) => {
        let selectedData: any = countNoData.find((data: any) => data.generateBarcodeDetailsId == e.target.value);
        setCountNo(selectedData.countNo)
        setGenerateBarcodeDetailsId(selectedData.generateBarcodeDetailsId)
        // getNextNoOfBundleList(selectedData.generateBarcodeDetailsId)
    };

    function getBundleLabelList() {
        setDataStatus(false)
        getBundleLabel(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.bundleLabel;
                    if (data != undefined) {
                        setBundleLabelData(data)
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataStatus(true)
                }
            }, error => {
                toasts(error, "Error")
                setDataStatus(true)
            }
        )
    }

    function setBundleLabelDetails() {
        return ({
            licenseKey: getLicenseKey,
            generateBarcodeDetailsId: generateBarcodeDetailsId,
            noOfBundle: '',
            crossWeight: assignBundleCountForm.values.crossWeight,
            netWeight: assignBundleCountForm.values.netWeight,
            bundleCount: assignBundleCountForm.values.bundleCount,
            userAccountId: userAccountId,
        })
    }

    function addBundleLabelSubmit() {
        showLoader(true)
        if (assignBundleCountForm.isValid) {
            let bundleLabelPostData = setBundleLabelDetails()
            addBundleLabel(bundleLabelPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getBundleLabelList()
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (assignBundleCountForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please Select Video!', type: "error" });
            setIsOpen(true);
        }
    }

    const [generateBarcodeDetailsData, setGenerateBarcodeDetailsData] = useState([])

    function getGenerateBarcodeDetailsList() {
        getGenerateBarcodeDetails(0, 1, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.generateBarcodeDetails;
                    if (data != undefined) {
                        setGenerateBarcodeDetailsData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [countNoData, setCountNoData] = useState([])

    function getGenerateBarcodeDetailsForDropdown(lotDetailsId: any) {
        getGenerateBarcodeDetails(lotDetailsId, 1, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.generateBarcodeDetails;
                    if (data != undefined) {
                        setCountNoData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
        assignBundleCountForm.resetForm()
        setGenerateBarcodeDetailsId('')
        setAddBundleLabelOffcanvas(false)
        setLotNo('')
    }

    return (
        <>
            <PageWrapper title={`Assign Bundle Count`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-7">
                                <CardTitle tag='div' className='h5'>Assigned Bundle List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Button color='primary' icon='Add' isLight onClick={() => setAddBundleLabelOffcanvas(true)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('lotNo')}
                                                className='cursor-pointer text-decoration-underline'>Lot No{' '}
                                                <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('countNo')}
                                                className='cursor-pointer text-decoration-underline'>Count No{' '}
                                                <Icon size='lg' className={getClassNamesFor('countNo')} icon='FilterList' />
                                            </th>                                           
                                            <th scope='col' onClick={() => requestSort('crossWeight')}
                                                className='cursor-pointer text-decoration-underline'>Cross Weight{' '}
                                                <Icon size='lg' className={getClassNamesFor('crossWeight')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('netWeight')}
                                                className='cursor-pointer text-decoration-underline'>Net Weight{' '}
                                                <Icon size='lg' className={getClassNamesFor('netWeight')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('boxCount')}
                                                className='cursor-pointer text-decoration-underline'>No Of Box For Bundle{' '}
                                                <Icon size='lg' className={getClassNamesFor('boxCount')} icon='FilterList' />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{bundleLabelData != "" ?<>{filteredData.map((item: any) => (<tr key={item.sno}><td>{item.sno}</td><td>{item.lotNo}</td><td>{item.countNo}</td><td>{item.crossWeight}</td><td>{item.netWeight}</td><td>{item.bundleCount}</td></tr>))}</>:<NoDataMsg columnsCount={10} msg={noDataMsg} />}</tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>

                    <OffCanvas setOpen={setAddBundleLabelOffcanvas} isOpen={addBundleLabelOffcanvas} titleId='addBundleLabelOffcanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={assignBundleCountForm.handleSubmit} isNotClose>
                        <OffCanvasHeader setOpen={closeAndReset}>
                            <OffCanvasTitle id='addBundleLabelOffcanvas'>Assign Bundle Count</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className='row g-4'>
                                <div className='col-12'>
                                    <FormGroup id='lotNo' label='Lot No'>
                                        <Select
                                            ariaLabel='Lot No'
                                            placeholder="Select Lot No"
                                            onChange={selectLotNo}
                                            value={lotNo}
                                            isValid={assignBundleCountForm.isValid}
                                            onBlur={assignBundleCountForm.handleBlur}
                                            isTouched={assignBundleCountForm.touched.lotNo}
                                            invalidFeedback={assignBundleCountForm.errors.lotNo}
                                            list={generateBarcodeDetailsData.map((data: any) => (
                                                { value: data.lotNo, text: data.lotNo }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='generateQrDetailsId' label='Count No'>
                                        <Select
                                            ariaLabel='Count No'
                                            placeholder="Select Count No"
                                            onChange={selectCountNo}
                                            value={generateBarcodeDetailsId}
                                            isValid={assignBundleCountForm.isValid}
                                            onBlur={assignBundleCountForm.handleBlur}
                                            isTouched={assignBundleCountForm.touched.generateBarcodeDetailsId}
                                            invalidFeedback={assignBundleCountForm.errors.generateBarcodeDetailsId}
                                            list={countNoData.map((data: any) => (
                                                { value: data.generateBarcodeDetailsId, text: data.countNo }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {/* <div className='col-12'>
                                    <FormGroup id='noOfBundle' label='No Of Bundle'>
                                        <Input onChange={assignBundleCountForm.handleChange} 
                                            value={noOfBundle}
                                            disabled                                           
                                            placeholder="Enter Bundle Count" />
                                    </FormGroup>
                                </div> */}
                                <div className='col-12'>
                                    <FormGroup id='crossWeight' label='Cross Weight'>
                                        <Input onChange={assignBundleCountForm.handleChange} value={assignBundleCountForm.values.crossWeight}
                                            isValid={assignBundleCountForm.isValid}
                                            onBlur={assignBundleCountForm.handleBlur}
                                            isTouched={assignBundleCountForm.touched.crossWeight}
                                            invalidFeedback={assignBundleCountForm.errors.crossWeight}
                                            placeholder="Enter Bundle Count" />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='netWeight' label='Net Weight'>
                                        <Input onChange={assignBundleCountForm.handleChange} value={assignBundleCountForm.values.netWeight}
                                            isValid={assignBundleCountForm.isValid}
                                            onBlur={assignBundleCountForm.handleBlur}
                                            isTouched={assignBundleCountForm.touched.netWeight}
                                            invalidFeedback={assignBundleCountForm.errors.netWeight}
                                            placeholder="Enter Bundle Count" />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='bundleCount' label='No Of Box For Bundle'>
                                        <Input onChange={assignBundleCountForm.handleChange} value={assignBundleCountForm.values.bundleCount}
                                            isValid={assignBundleCountForm.isValid}
                                            onBlur={assignBundleCountForm.handleBlur}
                                            isTouched={assignBundleCountForm.touched.bundleCount}
                                            invalidFeedback={assignBundleCountForm.errors.bundleCount}
                                            placeholder="Enter Bundle Count" />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='info' className='w-100' type="submit" icon="Save" isDisable={!assignBundleCountForm.isValid && !!assignBundleCountForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper >
        </>
    )
}
export default AssignBundleCount;