import showNotification from "../components/extras/showNotification";
import React from 'react'
import Icon from "../components/icon/Icon";


export const toasts = (message : any, title: any) => {

    const TITLE = (
        <span className='d-flex align-items-center'>
            <Icon icon='Info' size='lg' className='me-1' />
            <span>{title}</span>
        </span>
    );


    showNotification(TITLE, message, title == 'Error' ? 'danger' : 'success');
};